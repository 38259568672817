import React, { createContext, useContext, useEffect, useState } from "react";
import { ReservationContext } from "./reservationContext";
import api, { getLocale } from "../utils/api";
import config from "../config/config";
import MyAccount from "../utils/account";
import { useNavigate, useLocation } from "react-router-dom";
import useData from "../stores/dataStore";
import trackingUtils from "../utils/trackingUtils";
import useAccount from "../stores/accountStore";
import useStateWithSessionStorage from "../hooks/useSessionStorage";
import { useRouter } from "../hooks/useRouter";

export const SessionContext = createContext();

const SessionContextProvider = (props) => {
  const {
    clearEvents,
    refetchEvents,
    reservations,
    myself,
    reset: resetReservations,
    setMyself,
  } = useContext(ReservationContext);
  const [{ eventData }] = useData();
  const [session, setSession] = useStateWithSessionStorage(
    "session_" + eventData.id,
    null
  );
  const [{ profile }] = useAccount();
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);

  const location = useLocation();

  const { navigate } = useRouter();

  // check if no more reservations in session and delete it
  useEffect(() => {
    // check if not in participant process
    const inProgress =
      config.registration.dontRedirectFromTheseUrlsWhileBooking.includes(
        location.pathname.toLowerCase()
      );
    const hasReservation = reservations?.length > 0; // || session?.reservations?.length>0;

    if (profile && !hasReservation && session) {
      deleteSession();
    } else {
      session && updateSession(reservations);
    }
  }, [reservations]);

  const getReservationSession = (reservations) => {
    const sessionId = session.id;
    return {
      id: sessionId,
      eventId: session.eventId,
      accountId: profile ? profile.sub : "",
      participantWhoBooked: myself,
      reservations: reservations.map((event) => {
        return {
          programItemId: event.id,
          title: event.title,
          registration: {
            id: event.registration,
          },
          registrationId: event.registrationId,
          participants: event.participants,
        };
      }),
    };
  };

  const updateSession = (reservations) => {
    return new Promise((resolve, reject) => {
      if (!session) {
        newSession();
      }
      const sessionId = session.id;
      const reservationSession = getReservationSession(reservations);

      api
        .put(`${config.api.endpoints.session}/${sessionId}`, reservationSession)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          //reject(new Error(error));
        });
    });
  };

  const checkoutSession = (reservations) => {
    return new Promise((resolve, reject) => {
      const sessionId = session.id;
      trackingUtils.registrationCompleted(sessionId || "undefined");

      let reservationSession = getReservationSession(reservations);
      if (reservationSession) {
        if (reservationSession.reservations) {
          reservationSession.reservations.forEach((reservation) => {
            if (reservation.participants) {
              reservation.participants.forEach((participant, idx) => {
                console.log("EVCAL :: CHECKOUT participant participant", {
                  ...participant,
                });

                if (participant.isWaiting)
                  participant.WaitingSince = new Date().toUTCString();
              });
            }
          });
        }
      }

      reservationSession.participantWhoBooked = myself;
      if (!myself || !myself?.accountId) {
        reject("There was an unexpected error with you Account.");
        deleteSession();
      }

      api
        .put(
          config.api.endpoints.checkout
            .replace(":id", sessionId)
            .replace(":eventId", session.eventId)
            .replace(":language", getLocale()),
          reservationSession
        )
        .then((response) => {
          deleteSession();
          resolve(response);
        })
        .catch((error) => {
          deleteSession();
          reject(new Error(error));
        });
    });
  };

  const deleteSession = () => {
    if (session) {
      const sessionId = session.id;
      api
        .delete(`${config.api.endpoints.session}/${sessionId}`)
        .then((response) => {
          console.log("SESSION", "deleted");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setSession(null);
          clearEvents();
        });
    }
  };
  const onTimeout = (byUser = false, completed = false, _showModal = false) => {
    if (!completed) {
      byUser
        ? trackingUtils.registrationCancel(session ? session.id : "undefined")
        : trackingUtils.registrationTimeout(session ? session.id : "undefined");
    }
    deleteSession();
    _showModal && setShowTimeoutModal(true);
  };

  const getSession = async (getNewSession = false) => {
    session
      ? (await !isSessionValid()) &&
        (getNewSession ? newSession() : navigate("/timeout.html"))
      : getNewSession && newSession();
  };
  const isSessionValid = async () => {
    try {
      const res = await api.get(
        `${config.api.endpoints.session}/${session.id}`
      );

      return res.data != null;
    } catch (e) {
      return false;
    }
  };

  const newSession = () => {
    // create new session object
    const sessionData = {
      reservations: reservations.map((reservation) => ({
        title: reservation.title,
        date: reservation.date,
        programItemId: reservation.id,
        registrationId: reservation.registrationId,
        participants: reservation.participants,
      })),
      accountId: MyAccount.accountId,
      eventId: eventData.id,
    };

    console.log("------ NEW SESSION ------", session);

    api.post(config.api.endpoints.session, sessionData).then((response) => {
      const newSession = {
        ...response.data,
        eventId: eventData.id,
        accountId: MyAccount.accountId,
      };
      trackingUtils.registrationStart(newSession ? newSession.id : "undefined");

      setSession(newSession);
    });
  };

  return (
    <SessionContext.Provider
      value={{
        session,
        showTimeoutModal,
        setShowTimeoutModal,
        updateSession,
        isSessionValid,
        checkoutSession,
        getSession,
        updateSession,
        deleteSession,
        /*resetSession,*/ onTimeout,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;
