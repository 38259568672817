import React from "react";
import { Link, useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const LinkWithQuery = ({ children, to, ...props }) => {
  let query = useQuery();

  return (
    <Link to={`${to}${query ? `?${query}` : ""}`} {...props}>
      {children}
    </Link>
  );
};

export default LinkWithQuery;
