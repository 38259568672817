import React from 'react'

const Row = props => {
    return (
        <div {...props} className={`row${props.className ? ' '+props.className :''}`}>
            {props.children}
        </div>
    )
}

export default Row
