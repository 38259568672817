//------ SELECT OPTIONS ----------
const SelectOptions = {
  salutationSelectOptions: [
    { value: "male", label: "participantForm.mr" },
    { value: "female", label: "participantForm.ms" },
    { value: "not_specified", label: "participantForm.not_specified" },
  ],

  countryCodeSelectOptions: [
    { value: "AR", label: "participantForm.country.AR" },
    { value: "DE", label: "participantForm.country.DE" },
  ],

  titleSelectOptions: [
    { value: "null", label: "" },
    { value: "dr", label: "Dr." },
    { value: "dr_dr", label: "Dr. Dr." },
    { value: "dr_h_c", label: "Dr. h. c." },
    { value: "dr_ing", label: "Dr.-Ing." },
    { value: "pd_dr", label: "PD Dr." },
  ],

  businessStatusRadioItems: [
    { value: "freelance", title: "participantForm.freelance" },
    { value: "private", title: "participantForm.private" },
    { value: "employed", title: "participantForm.employed" },
  ],
};
export default SelectOptions;
