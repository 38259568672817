import config from "../config/config";
import api from "./api";
import { CookieHelper } from "./helpers";
/* global jQuery */
/* global MyAccountWidget */

// @TODO check if this file can be deleted after 2024.6.0

const MyAccount = {
  APPID: "MF-APP-0000MF",
  widget: config.myAccount.useCDC ? window.cdcaccwidget : window.accwidget,
  profile: null,
  accountId: "",
  onAfterLogin: null,
  redirectUrl: null,
  bookmarkedEvents: [],
  setWidget() {
    if (window) {
      this.widget = config.myAccount.useCDC
        ? window.cdcaccwidget
        : window.accwidget;
    }
  },
  initEventListeners() {
    this.setWidget();
    if (this.widget) {
      this.widget.onLogin = () => {
        //fires one time
        console.log("EVCAL ::  MyAccount", "onLogin");
        this.onLogin();
      };
      this.widget.onLogout = () => {
        console.log("EVCAL ::  MyAccount", "onLogout");
        this.onLogout();
      };
      if ({}.hasOwnProperty.call(this.widget, "loginDone")) {
        if (this.widget.isLoggedIn) {
          this.onLogin();
        } else {
          this.onLogout();
        }
      }
    }
  },
  setupWidget() {
    this.setWidget();
    if (this.widget) {
      this.widget = this.widget;
      this.widget.onLogin = () => {
        this.onLogin();
      };
      this.widget.onLogout = () => {
        this.onLogout();
      };
      this.isWidgetAvailable = true;
    }
  },
  init() {
    if (typeof jQuery !== "undefined") {
      jQuery(document).ready(() => {
        this.setupWidget();
        this.initEventListeners();
      });
    }
  },
  onLogout() {
    console.log("EVCAL ::  accountjs", "onLogout");
  },
  getLoginStatus() {
    return sessionStorage.getItem("loginHappened") ? "logged_in" : "logged_out";
  },
  isLoggedIn() {
    this.setWidget();
    return window && this.widget && this.widget.loggedIn === true
      ? true
      : false;
  },
  removeBookmark(id, programId) {
    return new Promise((resolve, reject) => {
      const bookmark = `${id}:0:${config.event.id}`;
      const fairHRID = config.event.findId;
      const url = config.myAccount.watchlist.remove;
      api
        .delete(url, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            apikey: config.api.gatewayKey,
          },
          data: JSON.stringify(
            [bookmark]
            /*fairHRID
              ? {
                  type: "event",
                  eventGUID: bookmark,
                  fairHRID: fairHRID,
                }
              : {
                  type: "event",
                  eventGUID: bookmark,
                }*/
          ),
        })
        .then(() => resolve())
        .catch(() => reject());
    });
  },
  parseWatchlistItem(eventGUID) {
    var data = eventGUID.split(":");
    return {
      eventId: parseInt(data[0]),
      programId: 0,
      fairId: parseInt(data[2]),
    };
  },
  addBookmark(id, programId) {
    return new Promise((resolve, reject) => {
      if (id && config.event.id) {
        const bookmark = `${id}:0:${config.event.id}`;
        const fairHRID = config.event.findId;
        const url = config.myAccount.watchlist.add;

        api
          .post(
            url,
            JSON.stringify(
              fairHRID
                ? {
                    type: "event",
                    active: true,
                    eventGUID: bookmark,
                    fairHRID: fairHRID,
                  }
                : {
                    type: "event",
                    active: true,
                    eventGUID: bookmark,
                  }
            ),
            {
              withCredentials: true,
              headers: {
                "Content-Type": "application/json",
                apikey: config.api.gatewayKey,
              },
            }
          )
          .then(() => resolve())
          .catch(() => reject());
      }
    });
  },
  openWidget() {
    if (this.widget) {
      this.widget.open();
    }
  },
  closeWidget() {
    if (this.widget) {
      this.widget.close && this.widget.close();
      this.widget.widgetIsOpen && this.widget.toggleWidget();
    }
  },
  onLogin(callback) {
    if (this.widget) {
      this.widget = this.widget;
      this.widget
        .getAccountData()
        .done((data) => {
          let newUserLogin = false;

          if (data.isLoggedIn) {
            this.profile = data.results.profile;
            this.idpApiUrl = this.widget.idpApiUrl;
            this.accountId = data.accountId;

            // if (data.MFSSOID) this.MFSSOID = data.MFSSOID;
            if (callback) callback(this.profile);

            if (newUserLogin) {
              document.dispatchEvent(new Event("aw.user.isLoggedIn"));
            }
            this.widgetReady = true;
          } else {
            this.profile = null;
            this.accountId = "";
          }
          if (this.onAfterLogin) {
            this.onAfterLogin();
          }
        })
        .fail((err) =>
          console.error(
            "EVK: Could not obtain accountdata. Tracking of account data not possible."
          )
        );
    }
  },
  getBookmarks(eventId) {
    return new Promise((resolve, reject) => {
      const url = config.myAccount.watchlist.get.replace(
        ":language",
        config.language
      );

      api
        .get(url, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            apikey: config.api.gatewayKey,
          },
        })
        .then((response) => {
          // if (response.data) {
          //   console.log("EVCAL : WATCHLIST : GET - RESPONSE", response.data);
          // }
          let watchlist =
            response.data && response.data.success
              ? response.data.result //response.data.result.items
                  .filter((it) => it.type === "event" && it.eventGUID)
                  .map((item) =>
                    this.parseWatchlistItem(
                      item.event ? item.event.eventGUID : item.eventGUID
                    )
                  )
              : [];

          if (watchlist.length == 0) {
            MyAccount.bookmarkedEvents = [];
            resolve([]);
          }

          const bookmarkedEventItemIds = watchlist
            .filter((item) => item.fairId === eventId)
            .map((item) => item.eventId);

          MyAccount.bookmarkedEvents = [...new Set(bookmarkedEventItemIds)];

          resolve(MyAccount.bookmarkedEvents);
        })
        .catch((err) => resolve(err));
    });
  },
  getProfile() {
    return new Promise((resolve, reject) => {
      MyAccount.onAfterLogin = null;
      if (1 < 2 && !config.useDatabase) {
        if (MyAccount.isLoggedIn) {
          MyAccount.onLogin(function (profile) {
            resolve(profile);
          });
        } else {
          MyAccount.onAfterLogin = MyAccount.getProfile();
          MyAccount.open();
        }
      } else {
        const url = config.myAccount.profile + "&_=" + new Date().getTime();

        api
          .get(url, {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            const data = response.data;
            if (!data.loggedIn) {
              this.profile = null;
              this.accountId = "";
              this.MFSSOID = null;
            }
            if (data.loggedIn) {
              this.profile =
                data && data.results && data.results.profile
                  ? data.results.profile
                  : {};

              this.accountId = data.accountId;
              // this.MFSSOID = data.MFSSOID;
              if (data.results) {
                var accountData = data.results.profile.person;
                var salutation = "not_specified";
                try {
                  if (accountData.salutation.localId === "MF-INT-000102")
                    salutation = "male";
                  if (accountData.salutation.localId === "MF-INT-000101")
                    salutation = "female";
                } catch (e) {}

                var myself = {
                  firstName: accountData.firstName || "",
                  lastName: accountData.lastName || "",
                  email: data.results.profile.account.email,
                  companyName: data.results.profile.company
                    ? data.results.profile.company.legalName
                    : "",
                  hasTicket: false,
                  businessStatus: data.results.profile.account.accountType,
                  myself: true,
                  salutation: salutation,
                  accountId: data.accountId,
                  countryCode: data.results.profile.account.countryIso3 || null,
                  image:
                    config.myAccount.imagePath +
                    accountData.accoundId +
                    "?size=140x140",
                };

                console.log("MYSELF", myself);

                var participants = JSON.parse(
                  sessionStorage.getItem("participants")
                );

                if (participants !== null && participants.length > 0) {
                  myself.hasTicket =
                    participants[0] !== null && participants[0] !== undefined
                      ? participants[0].hasTicket
                      : false;
                  if (
                    participants[0].firstName !== null &&
                    participants[0].firstName.length > 0 &&
                    (!myself.firstName || myself.firstName.length == 0)
                  )
                    myself.firstName = participants[0].firstName;
                  if (
                    participants[0].lastName !== null &&
                    participants[0].lastName.length > 0 &&
                    (!myself.lastName || myself.lastName.length == 0)
                  )
                    myself.lastName = participants[0].lastName;
                  participants[0] = myself;
                } else {
                  participants = [myself];
                }

                sessionStorage.setItem(
                  "participants",
                  JSON.stringify(participants)
                );
              }
              resolve(myself);
            }
          })
          .catch((err) => {
            resolve(err);
          });
      }
    });
  },
};

export default MyAccount;
