import React, { createContext, useContext, useEffect, useState } from "react";
import config from "../config/config";
import { DateHelper } from "../utils/helpers";
import useStateWithSessionStorage from "../hooks/useSessionStorage";
import ObjectID from "bson-objectid";

import api from "../utils/api";
import useAccount from "../stores/accountStore";

export const ReservationContext = createContext();
const STORAGE_NAME = config.reservationStorageName;

const ResevationContextProvider = (props) => {
  const [events, setEvents] = useStateWithSessionStorage(STORAGE_NAME, []);
  const [initialEvents, setInitialEvents] = useState([]);
  const [eventsToAdd, setEventsToAdd] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [participants, setParticipants] = useStateWithSessionStorage(
    "participants",
    []
  );
  const [{ profile }] = useAccount();

  useEffect(() => {
    if (profile) {
      setMyself(profile);
    } else {
      reset();
    }
  }, [profile]);

  const reset = () => {
    setParticipants(
      participants ? participants.filter((it) => !it.myself) : []
    );
    setEvents([]);
    setEventsToAdd([]);
  };

  const setMyself = (profile) => {
    if (profile) {
      var salutation = "not_specified";
      try {
        if (profile.gender === "m") salutation = "male";
        if (profile.gender === "w") salutation = "female";
      } catch (e) {}
      var _myself = {
        accountId: profile?.sub,
        hasTicket: false,
        myself: true,
        salutation: salutation,
        firstName: profile.firstName || "",
        lastName: profile.lastName || "",
        countryCode: profile.country,
        email: profile.email,
        companyName:
          profile.company && Array.isArray(profile.company)
            ? profile.company[0].legalName
            : "",
        image: profile.thumbnailURL || "",
      };

      // hier logik einbauen participants dlete und so
      if (!participants || (participants && Array.isArray(participants))) {
        if (!participants.find((it) => it.myself === true)) {
          addParticipant(_myself);
        }
      }
    }
  };

  const addParticipant = (participant) => {
    if (!participant.id) {
      participant.id = participant.myself
        ? participant.accountId
        : new ObjectID().toHexString();

      const foundParticipant = participants.find(
        (p) =>
          p.id === participant.id ||
          (participant?.sub?.length > 0 && p.sub === participant.sub) ||
          (participant.email.length > 0 && p.email === participant.email)
      );

      if (!foundParticipant) setParticipants([...participants, participant]);
    }
  };

  const initParticipants = () => {
    // TODO: delete
  };

  const updateParticipant = (input) => {
    let foundIndex = participants.findIndex(
      (participant) => participant.id === input.id
    );
    participants.splice(foundIndex, 1, input);

    updateParticipantInEvents(input);
    setParticipants([...participants]);
  };

  const removeParticipant = (id) => {
    setParticipants(
      participants.filter((participant) => participant.id !== id)
    );
  };

  const myself = participants.find((it) => it.myself) || {};
  // ----- END PARTICIPANTS

  const getInitialEvents = () => {
    var storage = JSON.parse(sessionStorage.getItem(STORAGE_NAME));
    if (storage && storage.length > 0) {
      setInitialEvents(storage);
      setIsLoading(false);
    }
  };

  const refetchEvents = () => {
    sessionStorage.setItem(STORAGE_NAME, JSON.stringify([]));
    clearEvents();
    getInitialEvents();
  };

  const findOverlaps = (items) => {
    return (
      items &&
      items.map((it) => {
        const startTime = it.startDate;
        const endTime = it.endDate;
        if (startTime && endTime) {
          const overlapsWith = items.filter((it2) => {
            const startTime2 = it2.startDate;
            const endTime2 = it2.endDate;
            if (startTime2 && endTime2 && it2.id !== it.id) {
              return DateHelper.checkOverlap(
                startTime,
                endTime,
                startTime2,
                endTime2
              );
            }
            return false;
          });
          if (overlapsWith && overlapsWith.length > 0) {
            it.overlapsWith = overlapsWith.map((ev) => ev.id);
          }
        }
        return it;
      })
    );
  };

  const clearEvents = () => {
    setEvents([]);
  };
  const clearEventsToAdd = () => {
    setEventsToAdd([]);
    clearEvents();
  };

  const hasConflict = (eventsIn) => {
    if (!eventsIn || eventsIn.length < 2 || !Array.isArray(eventsIn))
      return false;

    let _eventsIn = [...eventsIn];
    eventsIn.forEach((item) => {
      delete item.overlapsWith;
    });
    return findOverlaps(_eventsIn).filter((it) => it.overlapsWith).length > 0;
  };
  /*const hasReservationConflict = (eventsIn) => {
    return findOverlaps(eventsIn).filter(it=>it.overlapsWith).length>0;
  }*/

  const hasEventConflict = (eventsIn, type) => {
    var conflicts = { reservation: 0, participant: 0, event: 0 };
    if (eventsIn && eventsIn.length) {
      for (var ev of eventsIn) {
        if (ev.reservationConflicts && ev.reservationConflicts.length > 0) {
          conflicts.reservation++;
        }
        if (ev.participantConflicts && ev.participantConflicts.length > 0) {
          conflicts.participant++;
        }
        if (ev.overlapsWith && ev.overlapsWith.length > 0) {
          conflicts.event++;
        }
      }
    }

    if (!type) {
      return conflicts.reservation > 0 || conflicts.participant > 0;
    } else {
      return conflicts[type] > 0;
    }
  };

  const injectConflictsIntoEvents = (events) => {
    const checkParticipantConflict = (data, anotherEvent) => {
      if (anotherEvent.participants && anotherEvent.participants.length > 0) {
        for (const ap of anotherEvent.participants) {
          if (ap.id) {
            return data.participants.find((p) => {
              if (p.id) {
                return p.id === ap.id;
              }
              return false;
            });
          }
        }
      }

      return false;
    };

    const eventsAndConflicts = findOverlaps([...events]);
    for (var data of eventsAndConflicts) {
      data.reservationConflicts = [];
      data.participantConflicts = [];

      if (data.participants && data.participants.length > 0) {
        if (data.overlapsWith && data.overlapsWith.length > 0) {
          const overlapsWithParticipants = data.overlapsWith.filter((evID) => {
            const ev = events.find((it) => it.id === evID);
            console.log("CONFLICT EV", ev, !!ev, ev?.participants?.length);
            return ev && ev?.participants?.length > 0;
          });

          console.log(
            "CONFLICT 2 data.overlapsWithParticipants",
            data.overlapsWithParticipants
          );
          if (overlapsWithParticipants === undefined) {
            data.reservationConflicts = [...data.overlapsWith];
          }

          if (overlapsWithParticipants && overlapsWithParticipants.length > 0) {
            var overlapEvents = [];
            var _participantConflicts = [];
            console.log("CONFLICT 2 data.overlapsWith", data.overlapsWith);

            overlapsWithParticipants.map((evID) => {
              const ev = events.find((it) => it.id === evID);

              if (ev) {
                overlapEvents.push({
                  id: ev.id,
                  title: ev.title,
                  titles: ev.titles,
                });
                const participantConflict = checkParticipantConflict(data, ev);
                if (participantConflict) {
                  _participantConflicts.push(participantConflict);
                }
                return true;
              }
              return false;
            });

            data.reservationConflicts = overlapEvents;
            data.participantConflicts = _participantConflicts;
          }
        }
      }
    }
    return [...eventsAndConflicts];
  };

  const updateConflictsAndSetEvents = (events) => {
    const cleanedEvents = injectConflictsIntoEvents(events);
    setEvents(cleanedEvents);
  };

  // run once
  useEffect(() => {
    getInitialEvents();
    initParticipants();
  }, []);

  useEffect(() => {
    if (initialEvents) {
      const events = updateConflictsAndSetEvents(initialEvents);
    }
  }, [initialEvents]);

  const updateEvent = (input) => {
    var updatedEvents = [...events];
    let foundIndex = updatedEvents.findIndex((event) => event.id === input.id);
    foundIndex < 0
      ? updatedEvents.push(input)
      : updatedEvents.splice(foundIndex, 1, input);
    if (input.participants === null || input.participants.length <= 0)
      updatedEvents.splice(foundIndex, 1);
    updateConflictsAndSetEvents(updatedEvents);
    return reservations;
  };
  const updateEventToAdd = (input) => {
    let foundIndex = eventsToAdd.findIndex((event) => event.id === input.id);
    if (
      foundIndex >= 0 &&
      input.participants &&
      input.participants.length <= 0
    ) {
      // remove item from list
      eventsToAdd.splice(foundIndex, 1);
    } else {
      // add or update item
      foundIndex < 0
        ? eventsToAdd.push(input)
        : eventsToAdd.splice(foundIndex, 1, input);
    }
    setEventsToAdd([...eventsToAdd]);
  };
  const removeEvent = (_event) => {
    removeEventById(_event.id);
  };
  const removeEventById = (_id) => {
    let _events = [...events.filter((e) => e.id !== _id)];
    updateConflictsAndSetEvents([..._events]);
  };

  const cancelEvent = (_eventId, _eventItemId, _accountId, _participants) => {
    return api.post(config.api.endpoints.cancel, {
      eventId: _eventId,
      eventItemId: _eventItemId,
      accountId: _accountId,
      participantIds: _participants.map((p) => p.id),
    });
  };

  const addEvents = (newEvents) => {
    //todo loop through events and remove duplicates before adding to reservations
    if (events.findIndex((e) => e.id === newEvents[0].id) < 0) {
      updateConflictsAndSetEvents([...events, ...newEvents]);
    }
  };
  const addEvent = (newEvent, setToWaitingList = false) => {
    let _events = [...events];
    let foundEvent = _events.find((e) => e.id === newEvent.id);
    if (foundEvent) {
      if (!foundEvent.participants) foundEvent.participants = [];
      const participantToAdd = {}; //foundEvent.participants?.length>0 ? {} : myself;
      foundEvent.participants.push({
        ...participantToAdd,
        isWaiting: setToWaitingList,
      });
      updateConflictsAndSetEvents([..._events]);
    } else {
      const participantToAdd = {}; //{...myself};

      newEvent.participants = [
        { ...participantToAdd, isWaiting: setToWaitingList },
      ];
      updateConflictsAndSetEvents([..._events, newEvent]);
    }
    //}
  };

  const addEventsToReservation = (merge) => {
    if (eventsToAdd.length > 0) {
      merge === true
        ? updateConflictsAndSetEvents([...events, ...eventsToAdd])
        : updateConflictsAndSetEvents([...eventsToAdd]);
    }
    setEventsToAdd([]);
  };

  const updateParticipantInEvents = (participant) => {
    let eventIndex = 0;
    for (var event of events) {
      let participantIndex = 0;
      for (var p of event.participants) {
        if (p.id == participant.id) {
          events[eventIndex].participants[participantIndex] = participant;
        }
        participantIndex++;
      }
      eventIndex++;
    }
    updateConflictsAndSetEvents(events);
  };

  const reservations = !events
    ? []
    : events.filter(
        (event) => event.participants && event.participants.length > 0
      );

  const allParticipantsSelected =
    [...reservations]
      .reduce((arr, value) => {
        return arr.concat(value.participants);
      }, [])
      .filter((it) => it && !it.id).length <= 0;

  return (
    <ReservationContext.Provider
      value={{
        isLoading,
        reset,
        setMyself,
        cancelEvent,
        removeEventById,
        removeEvent,
        addEvent,
        clearEventsToAdd,
        hasConflict,
        events,
        eventsToAdd,
        addEvents,
        updateParticipantInEvents,
        addEventsToReservation,
        reservations,
        refetchEvents,
        clearEvents,
        updateEventToAdd,
        updateEvent,
        allParticipantsSelected,
        hasEventConflict,
        injectConflictsIntoEvents,
        myself,
        participants,
        setParticipants,
        addParticipant,
        updateParticipant,
        removeParticipant,
      }}
    >
      {props.children}
    </ReservationContext.Provider>
  );
};

export default ResevationContextProvider;
