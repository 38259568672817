import React,{useEffect} from 'react';
import { useLocation } from "react-router-dom";
import trackingUtils from '../../utils/trackingUtils';

function usePageViews() {
    let location = useLocation();
    useEffect(() => {
      const pathname = location.pathname.replace("/","");
      trackingUtils.onChangePage(pathname ? pathname : 'index');
    }, [location]);
}

const WebtrekkProvider = props => {
    usePageViews();

    return ( <>{props.children}</> );
}
 
export default WebtrekkProvider;