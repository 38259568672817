import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import config from '../config/config';

function getYYDDHH() {
  const now = new Date();

  // Get two-digit year (YY)
  const year = now.getFullYear().toString().slice(-2);

  // Get day of the year (DDD)
  const startOfYear = new Date(now.getFullYear(), 0, 0);
  const diff = now - startOfYear;
  const oneDay = 1000 * 60 * 60 * 24;
  const dayOfYear = Math.floor(diff / oneDay);

  // Get two-digit hour (HH)
  const hour = now.getHours().toString().padStart(2, '0');

  return `${year}${dayOfYear}${hour}`;
}


i18n
  .use(Backend)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    fallbackLng: 'en',
    debug: false,
    lng: config.locale,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: `${process.env.REACT_APP_I18N_URL ||  '/locales/{{lng}}/{{ns}}.json'}?ts=${getYYDDHH()}`,
      crossDomain: true,
    }
  });


export default i18n;