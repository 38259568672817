import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { media } from "../../utils/media";

const StyledModal = styled.div`
  position: fixed;
  z-index: 1039;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  .overlay-close {
    cursor: pointer;
  }

  .overlay-content {
    overflow-x: hidden;
    background-color: #fff;

    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: 40px 20px;
    ${(props) => props.greyBackground && "background-color: #f3f3f3;"}

    ${media.tablet` height:auto;`}
  }
  @media screen and (min-width: 1024px) {
    .overlay-content {
      padding: 55px 100px;
    }
  }
  @media screen and (min-width: 768px) {
    .overlay-content {
      max-width: 920px;
      margin: 0 auto;
      max-height: 90%;
      //top: 5%;
      padding: 64px 50px;
    }
  }

  .overlay-close {
    font-size: 18px;
    transition: transform 400ms ease;
    transition: -webkit-transform 400ms ease;
    padding: 0;
    margin: 30px 30px 0 0;
    position: absolute;
    display: inline-block;
    padding: 30px;
    font-size: 3.6em;
    right: 0;
    top: -20px;
    /* top: 14px; */
    span {
      color: #afb0b4;
      border-color: #afb0b4;
      font-size: 20px;
    }
  }
`;

// Creates a portal outside the DOM hierarchy
function Portal({ children }) {
  const modalRoot = document.querySelector(
    'div[data-application-name="mf-evcal-root"]'
  ); // A div with id=modal-root in the index.html
  const element = document.createElement("div"); // Create a div element which will be mounted within modal-root
  useEffect(() => {
    modalRoot && modalRoot.parentElement.appendChild(element);

    // cleanup method to remove the appended child
    return function cleanup() {
      modalRoot && modalRoot.parentElement.removeChild(element);
    };
  });

  return createPortal(children, element);
}

const Modal = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen || false);

  useEffect(() => {
    isOpen != props.isOpen && setIsOpen(props.isOpen);
    return () => {}; //unmount;
  });

  const handleClose = (e) => {
    if (e.target.classList.contains("js-overlay-close")) {
      setIsOpen(false);
      props.onClose && props.onClose();
    }
  };

  return (
    <>
      {
        <Portal>
          {isOpen && (
            <StyledModal
              {...props}
              onClick={handleClose}
              className={`evcal__wrapper overlay js-overlay-close animated fast ${
                isOpen && "fadeIn"
              }`}
            >
              <div
                className={`overlay-content animated fast ${
                  isOpen && "fadeIn"
                }`}
              >
                <div>{props.children}</div>
                <div
                  style={{ display: "block", padding: "4px" }}
                  className="overlay-close js-overlay-close color-light color-event-hover"
                >
                  <span className="icon js-overlay-close icon-close"></span>
                </div>
              </div>
            </StyledModal>
          )}
        </Portal>
      }
    </>
  );
};

export default Modal;
