export const ListLayouts = {
    LIST: "LIST",
    GRID: "GRID",
    COMPACT: "COMPACT"
}
export const ListLayoutsCssClasses = {
    LIST: {
        DATE: 'col-xxs-8 col-xs-7 col-sm-4 col-md-2 col-lg-2',
        TITLE: 'col-xs-12 col-sm-6 col-md-8 col-lg-8 col-md-pull-2 col-lg-pull-2 col-sm-pull-3 flex-md-last',
        ACTIONS: 'col-xxs-4 col-xs-5 col-sm-2 col-md-2 col-sm-push-6 col-md-push-8 col-lg-push-8',
    },
    GRID: {
        DATE: 'col-xxs-8 col-xs-7',
        TITLE: 'col-xs-12',
        ACTIONS: 'col-xxs-4 col-xs-5',
    },
    COMPACT: {
        DATE: 'col-xxs-8 col-xs-7 col-sm-4 col-md-2 col-lg-2',
        TITLE: 'col-xxs-12 col-sm-6 col-md-8 col-lg-8 flex-md-last',
        ACTIONS: 'col-xxs-4 col-xs-5',
    }
}
export const Layout = {
    DEFAULT: "default",
    SLIM: "slim"
}

function mapLocales(lang) {
    if (lang === 'de-DE') return 'de';
    if (lang.indexOf('en') === 0) return 'en';
    if (lang.indexOf('es') === 0) return 'es';
    if (lang.indexOf('fr') === 0) return 'fr';
    return initialValues.LOCALE;
}

const isDevelopment = process.env.REACT_APP_ENV === 'development';
const isProduction = process.env.REACT_APP_ENV === 'production';
const useDatabase = process.env.REACT_APP_USE_DB === 'true';
const baseApiUrl = `${useDatabase ? process.env.REACT_APP_API_URL : process.env.REACT_APP_MOCKAPI_URL}`;
const myAccountBaseUrl = `${process.env.REACT_APP_MYACCOUNT_URL}`;
const useApiGateway = process.env.REACT_APP_USE_GATEWAY || false;
const GATEWAY_KEY = process.env.REACT_APP_GATEWAY_KEY;
const GATEWAY_URL = process.env.REACT_APP_API_GW_URL;
const IDP_USER_INFO_URL = process.env.REACT_APP_IDP_USER_INFO_URL || "";
const USE_CDC = process.env.REACT_APP_USE_CDC || false;
const GATEWAY_WATCHLIST_URL = `${process.env.REACT_APP_GW_WATCHLIST_URL}`;
const GATEWAY_EVCAL_SERVICE = `${process.env.REACT_APP_GW_EVCAL_URL}`;


const replaceHostName = (url) => {
    if(!url)return "";
    if (window) {
        const currentSLD = window.location.hostname.split('.').reverse()[1];
        const apiSLD = (currentSLD !== undefined) ? currentSLD : 'messefrankfurt';

        return  url.replace('messefrankfurt', apiSLD)
    }
    else {
        return url;
    }
}

const MyAccountConfig = {
    useCDC: USE_CDC,
    loginUrl: `${replaceHostName(process.env.REACT_APP_CDC_LOGIN)}?apiKey=${process.env.REACT_APP_CDC_KEY}&redirectUrl=:redirectUrl`,
    sessionUrl: replaceHostName(process.env.REACT_APP_CDC_SESSION_URL),
    baseUrl: (myAccountBaseUrl && myAccountBaseUrl.length > 0) ? myAccountBaseUrl : initialValues.MY_ACCOUNT_URL,
    imagePath: myAccountBaseUrl + '/myaccount-service/content/public/profile/image/',
    profile: myAccountBaseUrl + '/myaccount-service/api/2.1/public/profile?appid=MF-APP-0000MF&subsidiaryId=MF-SUB-GROUP',
    watchlist: {
        get:  replaceHostName(GATEWAY_WATCHLIST_URL) + '/2.1/watchlist/public/type/event',
        remove: replaceHostName(GATEWAY_WATCHLIST_URL) + '/2.1/watchlist/public',
        add: replaceHostName(GATEWAY_WATCHLIST_URL) + '/2.1/watchlist/public/entry'
    },
    userInfo: replaceHostName(IDP_USER_INFO_URL),
    getWidget() {
        if (!window) return null;
        return USE_CDC ? window.cdcaccwidget : window.accwidget
    }
};

let initialValues = {};
let firstNavItemColorInAEM = null;
if (document) {
    let appEntryPoint = document.querySelector('div[data-application-name="mf-evcal-root"]')
    if (appEntryPoint && appEntryPoint.getAttribute('data-config')) {
        Object.assign(initialValues, JSON.parse(appEntryPoint.getAttribute('data-config')));
    }
    try {
        firstNavItemColorInAEM = getComputedStyle(document.getElementsByClassName('o-off-canvas__item-link')[0]).color;
    } catch (e) { console.warn('Could not get computedStyle for first link item on messefrankfurt.com') }
}
const config = {
    myAccount: MyAccountConfig,
    isProduction: isProduction,
    useDatabase: useDatabase,
    isDevelopment: isDevelopment,
    api: {
        useApiGateway: useApiGateway,
        gatewayKey: GATEWAY_KEY,
        baseUrlWithoutGateway: baseApiUrl,
        baseUrl: useApiGateway ? `${GATEWAY_URL}` : baseApiUrl,
        endpoints: {
            health: GATEWAY_EVCAL_SERVICE + `/health`,
            eventData: GATEWAY_EVCAL_SERVICE + `/v2/events/permalink/:permalink?lang=:language`,
            textTemplates: GATEWAY_EVCAL_SERVICE + `/mailtemplates/public/event/:eventId/?lang=:language`,
            registration: GATEWAY_EVCAL_SERVICE + `/registrations`,
            registrationData: baseApiUrl + `/v2/eventitems/getregistrationdata?programitemid=:programItemId&myaccountid=:accountId`,
            registrationState: GATEWAY_EVCAL_SERVICE + `/v2/eventitems/getregistrationstate?programitemid=:programItemId&myaccountid=:accountId`,
            alreadyBookedAtSameTime: baseApiUrl + `/v2/eventitems/bookedreservationsametime/?eventid=:eventId&programitemdd=:programItemId&accountid=:accountId`,
            watchlist: GATEWAY_EVCAL_SERVICE + `/v2/eventitems/watchlist?eventid=:eventId&lang=:language&accountid=:accountId`,
            eventItems: GATEWAY_EVCAL_SERVICE + `/v2/eventitems/event/:eventId?lang=:language`,
            eventItemsWithAllData: GATEWAY_EVCAL_SERVICE + `/v2/eventitems/event/:eventId?lang=:language&includedescription=true`,
            eventDetail: GATEWAY_EVCAL_SERVICE + `/v2/eventitems/event/:eventId/:id?withregistration=false&lang=:language`,
            eventDetailWithRegistrationState: GATEWAY_EVCAL_SERVICE + `/v2/eventitems/event/:eventId/:id?withregistration=true&lang=:language&myaccountid=:accountId`,
            setCardDetail: GATEWAY_EVCAL_SERVICE + `/v2/setcards/:id?lang=:language`,
            export: '/ical/?eventids=:eventIds&eventid=:eventId&lang=:language',
            exportAll: '/ical/?eventids=:eventIds&eventid=:eventId&lang=:language',
            parallelEventSearchCount: GATEWAY_EVCAL_SERVICE + `/v2/eventitems/event/:eventId/count?lang=:language&q=:q`,
            session: GATEWAY_EVCAL_SERVICE + `/reservationsessions`,
            checkout: GATEWAY_EVCAL_SERVICE + `/reservationsessions/checkout/:id?eventId=:eventId&lang=:language`,
            cancel: baseApiUrl + `/reservationsessions/cancel`,
            alreadyBooked: GATEWAY_EVCAL_SERVICE + `/v2/eventitems/alreadybooked/?eventid=:eventId&accountid=:accountId`, // not used?
            image: baseApiUrl + `/image?file={{file}}&w={{w}}`,
            imageDeprecated: baseApiUrl + `/image?file={{file}}&cropX={{cropX}}&cropY={{cropY}}&cropW={{cropW}}&cropH={{cropH}}&w={{w}}`,
        }
    },
    event: {
        id: null,
        findId: null,
        color: initialValues.EVENT_COLOR ? initialValues.EVENT_COLOR : (firstNavItemColorInAEM || '#303030'),
        permalink: initialValues.EVCAL_EVENT_PERMALINK
    },
    externalAppliction: {
        id: initialValues.ID,
        headline: initialValues.HEADLINE || '[HEADLINE] IS MISSING',
        buttonText: initialValues.BUTTON_TEXT || '[BUTTON_TEXT] IS MISSING',
        linkText: initialValues.LINK_TEXT || '[LINK_TEXT] IS MISSING',
    },
    i18nUrl: process.env.REACT_APP_I18N_URL,
    isPreview: initialValues.PREVIEW ? initialValues.PREVIEW.toLowerCase() === "true" : false || false,
    fallbackLanguageKey: 'en',
    //locale: initialValues.LOCALE ? mapLocales(initialValues.LOCALE) :'en',
    //language: initialValues.LOCALE || 'en-GB',
    language: initialValues.LOCALE ? mapLocales(initialValues.LOCALE) : 'en',
    lang: initialValues.LOCALE ? initialValues.LOCALE.substring(0, 2) : 'en',
    locale: initialValues.LOCALE || 'en-GB',
    //pageSize: null,
    searchPage: initialValues.SEARCH_PAGE || null,
    basePath: initialValues.EVCAL_BASE_PATH || '/',
    limit: initialValues.MAX_RESULT_SIZE || null,
    layout: initialValues.LAYOUT ? initialValues.LAYOUT.toLowerCase() : Layout.DEFAULT,
    defaultSettings: {
        listLayout: initialValues.LIST_LAYOUT ? initialValues.LIST_LAYOUT.toUpperCase() : ListLayouts.LIST,
        expandParticipanAccordion: true
    },
    registration: {
        endpoints: {
            cancel: '/'
        },
        dontRedirectFromTheseUrlsWhileBooking: [
            '/summary.html',
            '/participants.html',
            '/summary.html',
            '/confirmation.html'
        ]
    },
    gui: {
        showTimezoneInformation: process.env.REACT_APP_SHOW_TIMEZONE_INFORMATION || false,
    },
    reservationStorageName: 'events_' + initialValues.EVCAL_EVENT_PERMALINK?.replace('/','--'),
    assetsPath: process.env.REACT_APP_ASSETS_URL,
    isDefaultLayout: () => {
        return config.layout === Layout.DEFAULT
    },
    setSlimLayout: () => {
        config.layout = Layout.SLIM;
    }
};

export default config;