import { createStore, createHook } from 'react-sweet-state';
import config from '../config/config';
import MyAccount from '../utils/account';

const POST_LOGIN_ACTION_STORE = 'EVCAL__POST_LOGIN_ACTION';

const AccountStore = createStore({
  initialState: {
    profile: null,
    isLoggedIn: false,
    redirectUrl: null,
    tryAddBookmarkForEventItemId: null,
    tryAddRegisationForEventItemId: null,
    bookmarks: [],
    initializedBookmarks: false
  },
  // actions that trigger store mutation
  actions: {
    initAccountStore: () => ({ setState }) => {

      if (MyAccount.widget) {
        MyAccount.widget.onLogin = () => {
          setState({
            isLoggedIn: true
          });
        };
        MyAccount.widget.onLogout = () => {
          setState({
            isLoggedIn: false
          });
        };
      }
    },
    setBookmarks: (_bookmarks, allEventItems) => ({ setState }) => {
      _bookmarks && Array.isArray(_bookmarks) &&
        setState({
          initializedBookmarks:true,
          bookmarks: _bookmarks.filter(bookmark =>
            allEventItems.find(eventItem => eventItem.id === bookmark)),
        });
    },
    showLogin: (postLoginUrl, postLoginAction) => () => {
      const redirectUrl = postLoginUrl ? (window.location.origin + "/" + window.location.search + "#/" + postLoginUrl) : window.location.href;
      const url = config.myAccount.loginUrl.replace(':redirectUrl', encodeURIComponent(redirectUrl));
      localStorage.setItem(POST_LOGIN_ACTION_STORE, postLoginAction);
      window.location.replace(url);
    },
    fetchProfile: () => async ({ dispatch }) => {
      const responseUserInfo = await fetch(config.myAccount.userInfo, { credentials: "include", });
      const resopnseUserInfoText = await responseUserInfo.text();
      const responseUserInfoObj = JSON.parse(resopnseUserInfoText);
      const userInfo = responseUserInfoObj.result;
      dispatch(AccountStore.actions.setProfile(userInfo));

    },
    setProfile: (userInfo) => ({ setState,dispatch }) => {
      setState({ profile: userInfo, isLoggedIn: true });

      // fire postLoginAction and delete the entry
      const postLoginAction = localStorage.getItem(POST_LOGIN_ACTION_STORE);
      if (postLoginAction) {
        console.log('postLoginAction', postLoginAction)
        const [actionCategory,actionName,actionValue] = postLoginAction.split(':');
        if(actionCategory==='watchlist'){
          if(actionName==="add") 
            setState({
              tryAddBookmarkForEventItemId: parseInt(actionValue)
            });/*dispatch(AccountStore.actions.addBookmark(parseInt(actionValue)))*/
        }
        if(actionCategory==='registration'){
          if(actionName==="add") setState({
            tryAddRegisationForEventItemId: parseInt(actionValue)
          });
        }
      }
      localStorage.removeItem(POST_LOGIN_ACTION_STORE);


      if (!userInfo) {
        const currentRoute = document?.location?.href?.split('/').splice(-1)[0];
        if (config.registration.dontRedirectFromTheseUrlsWhileBooking.includes(`/${currentRoute}`)) {
          window.location.replace(
            window.location.href.replace(`/${currentRoute}`, `/timetable.html`),
          );
        }

        sessionStorage.setItem(
          'session_' + config.event.id,
          null
        )
        sessionStorage.setItem(
          config.reservationStorageName,
          []
        )
        sessionStorage.setItem(
          "participants",
          []
        );

        return true;
      }
    },
    setRedirectUrl: (value) => ({ setState }) => {
      setState({
        redirectUrl: value
      });
    },
    removePostLoginVars: () => ({ setState }) => {
      setState({
        tryAddBookmarkForEventItemId: null,
        tryAddRegisationForEventItemId: null
      });
    },
    isLoggedIn: () => ({ setState, getState }) => {
      return getState().isLoggedIn;
    },
    login: () => ({ setState }) => {
      setState({
        isLoggedIn: true
      });
    },
    logout: () => ({ setState }) => {
      setState({
        isLoggedIn: false,
        profile: null,
        bookmarks: []
      });
    },
    removeBookmark: (id) => ({ setState, getState }) => {
      setState({
        bookmarks: [...getState().bookmarks.filter(it => it !== id)],
      });
    },
    addBookmark: (id, programId) => ({ setState, getState }) => {
      setState({
        bookmarks: [...getState().bookmarks, id],
      });
    },
    logoutOld: () => ({ setState }) => {
      setState({
        profile: null,
        isLoggedIn: false,
        bookmarks: []
      });
    },
  }
});

const useAccount = createHook(AccountStore);
export default useAccount;
