import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../components/elements/button";
import { breakpoints } from "../utils/media";
import Icon from "../components/elements/icon";
import Row from "../components/layout/row";
import { SessionContext } from "../contexts/sessionContext";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import trackingUtils from "../utils/trackingUtils";
import { useRouter } from "../hooks/useRouter";

const StyledWrapperSuccess = styled.div`
  text-align: center;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 30px;
  & .icon {
    width: 80px;
    margin-bottom: 30px;
    font-size: 6em;
  }
  & h4 {
    width: auto;
    font-weight: 700;
  }
  & .success_body {
    width: auto;
    font-size: 16pt;
  }
`;

const StyledInfoBox = styled.div`
  background-color: grey;
  color: white;
  display: flex;
  padding: 20px 15px 20px 15px;

  @media (max-width: ${breakpoints.mobile + "px"}) {
    flex-wrap: wrap;
  }

  & svg.info {
    width: 20px;
  }
  & .info-box-msg {
    flex: 1 1 60%;
    padding: 0px 15px 0px 15px;
    & p {
      color: white;
      width: 100%;
    }
    & span.info-box-bold {
      font-weight: 700;
    }
  }
  & div.info-box-button-wrapper {
    flex: 0 1 auto;

    @media (max-width: ${breakpoints.mobile + "px"}) {
      width: 100%;
      flex: 0 1 auto;
      text-align: center;
    }
  }
`;

const SessionTimeout = ({ isModal }) => {
  const { t, i18n } = useTranslation();
  const { onTimeout, setShowTimeoutModal } = useContext(SessionContext);
  const { navigate } = useRouter();

  useEffect(() => {
    if (!isModal) {
      scroll.scrollToTop();
      onTimeout(true, true);
    }
  }, []);
  const goToTimetable = () => {
    trackingUtils.onRestartSession();
    !isModal ? navigate("/") : setShowTimeoutModal(false);
  };

  return (
    <>
      <StyledWrapperSuccess>
        <span>
          <Icon type="warning" />
        </span>
        <h4>{t("session.expired")}</h4>
        <div className="success_body">{t("session.expired_text")}</div>
      </StyledWrapperSuccess>

      <Row>
        <div className="col-xxs-12 text-center">
          <Button onClick={goToTimetable} primary>
            {t(isModal ? "functions.close" : "session.startOver")}
          </Button>
        </div>
      </Row>
    </>
  );
};

export default SessionTimeout;
