import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { SessionContext } from "../../contexts/sessionContext";

import dayjs from "dayjs";
import { useTimer } from "react-timer-hook";
import config from "../../config/config";
import { useRouter } from "../../hooks/useRouter";

const StyledContainer = styled.div`
  font-size: 90%;
  .session-clock {
    font-size: 2.6em;
    font-weight: 300;
    margin: 0;
    opacity: ${(props) => (props.isLoading ? "0.1" : "0.8")};
  }
`;

const Clock = (props) => {
  const { t } = useTranslation();
  const { session, getSession, onTimeout } = useContext(SessionContext);
  const location = useLocation();
  const [expiryTimestamp, setExpiryTimestamp] = useState(null);
  const { navigate } = useRouter();

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      // check if redirect to page or show modal content
      config.registration.dontRedirectFromTheseUrlsWhileBooking.includes(
        location.pathname.toLowerCase()
      )
        ? navigate("/timeout.html")
        : onTimeout(false, false, true);
    },
  });

  useEffect(() => {
    getSession(location.pathname !== "/summary.html");
    if (session) {
      setExpiryTimestamp(
        dayjs.utc(session.sessionStart).local().add(10, "minutes").toDate()
      );
    }

    return () => {};
  }, [session]);

  useEffect(() => {
    restart(expiryTimestamp);
  }, [expiryTimestamp]);

  return (
    <StyledContainer isLoading={!expiryTimestamp}>
      <div>{t("clock.yourTicketsAre")}</div>
      <div className="session-clock">
        <span>{minutes < 10 ? "0" + minutes : minutes}</span>:
        <span>{seconds < 10 ? "0" + seconds : seconds}</span>
      </div>

      <div>{t("clock.minutesReserved")}</div>
    </StyledContainer>
  );
};

export default Clock;
