import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import config from '../config/config';
import Icon from '../components/elements/icon';
import useData from '../stores/dataStore';
import { Themes } from '../stores/themeStore';
import mockData from '../data/mock/mockData';
import useAccount from '../stores/accountStore';

const StyledHeader = styled.div`
  position:fixed;
  top: -2px;
  right: -2px;
  z-index: 80;
  width: 350px;

  div,button {
    position:absolute;
    top: 0;
    right: 0;
    border: none;
    border-radius:0;
  }
  button {
    border: 2px solid black;
  }
  div {
    right: 0;
    background-color: white;
    border: 2px solid black;
    padding: 40px 20px 20px;
    box-shadow: 0 5px 17px 3px rgba(0,0,0, .3);
    li{
      padding:0;
    }
    ul{
      margin:0;
      margin-top: -50px;
      overflow: scroll;
    }
  }
  .seperator {
    border-top: 1px solid #dedede;
  }
`

const FakeHeader = () => {
  const { i18n } = useTranslation();
  const [isOpen, open] = useState(false);
  const [{eventData,apiOffline,theme,alwaysShowStatus},{setApiOffline,setTheme,fetchData,setShowStatus}]=useData();
  const [{ isLoggedIn }, { setProfile },] = useAccount();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }
  const fakeLoginBasti = () => {
    !isLoggedIn && setProfile(mockData.CDCProfileBasti)
    open(false)
  }
  const fakeLoginPeter = () => {
    !isLoggedIn && setProfile(mockData.CDCProfilePeter)
    open(false)
  }
  const changeLocale = (lng) => {
    config.locale=lng;
    i18n.changeLanguage(lng);
    fetchData();
  }
  const switchTheme = () => {
    setTheme(theme===Themes.DEFAULT ? Themes.COMPACT : Themes.DEFAULT)
  }
  const switchOfflineMode = () => {
    setApiOffline(!apiOffline)
  }
  const switchStatus = () => {
    setShowStatus(!alwaysShowStatus);
  }
  return (<StyledHeader>
    {
      isOpen &&

      <div>
        <ul className=" unstyled">
          <li>
            <pre>EventID: {eventData && eventData.id}</pre>
          </li>
          <li>
            <Link data-reference="timetable" to="/">TimeTable</Link>
          </li>
          <li>
            <Link data-reference="watchlist" to="/watchlist.html">Watchlist</Link>
          </li>
          <li>
            <Link data-reference="setCards" to="/setCards.html">Set-Cards</Link>
          </li>
          <li>
            <Link data-reference="participants" to="/participants.html">Participants</Link>
          </li>
          <li>
            <Link data-reference="summary" to="/summary.html">Summary</Link>
          </li>
          <li>
            <Link data-reference="confirmation" to="/confirmation.html">Confirmation</Link>
          </li>
          <li className="seperator"></li>
          <li>
            <Link data-reference="timeout" to="/timeout.html">Timeout</Link>
          </li>
          <li>
            <Link data-reference="playground" to="/playground.html">Playground</Link>
          </li>
    
          <li className="seperator"></li>
          <li>
            <span className={`btn-link ${i18n.language === 'de' && 'active'}`} onClick={() => changeLanguage('de')}>de</span>
          </li>

          <li>
            <span className={`btn-link ${i18n.language === 'en' && 'active'}`} onClick={() => changeLanguage('en')}>en</span>
          </li>
          <li>
            <span className={`btn-link ${i18n.language === 'en-US' && 'active'}`} onClick={() => changeLocale('en-US')}>en-US</span>
          </li>
          <li>
            <span className={`btn-link ${i18n.language === 'zh-CN' && 'active'}`} onClick={() => changeLanguage('zh-CN')}>zh-CN</span>
          </li>
          <li className="seperator"></li>
          <li>
            <span className={`btn-link`} onClick={() => switchTheme()}>Switch Theme</span>
          </li>
          <li>
            <span className={`btn-link`} onClick={() => switchStatus()}>Toggle Status</span>
          </li>
          <li>
            <span className={`btn-link`} onClick={() => switchOfflineMode()}>Toggle Offline-Mode (temporary)</span>
          </li>
          <li>
            <span className={`btn-link`} onClick={() => fakeLoginBasti()}>Fake Login Basti</span>
          </li>
          <li>
            <span className={`btn-link`} onClick={() => fakeLoginPeter()}>Fake Login Peter</span>
          </li>
        </ul>

      </div>}
    <button onClick={() => open(() => !isOpen)}><Icon type="settings" /></button>
    </StyledHeader>);
}

export default FakeHeader;