import React, { useState,useEffect,useRef } from "react";
import { useLocation, useParams } from "react-router-dom";

export const LastLocationContext = React.createContext();

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const NAVIGATION_STORAGE = "evcal__navHistory";

const LastLocationProvider = ({children}) => {
  const location = useLocation()
  const [route, setRoute] = useState({ 
    cur: location.pathname
  });

  const previousLocation = usePrevious(location);



  useEffect((p)=> {


    let locationHistory = {cur: location.pathname,
      prev: previousLocation?.pathname};
    
      const locationHistoryFromStore = JSON.parse(sessionStorage.getItem(NAVIGATION_STORAGE));

      if(locationHistoryFromStore){
      if(locationHistoryFromStore?.cur===locationHistory.cur && !locationHistory.prev){
        locationHistory.prev = locationHistoryFromStore.prev;
      }
     locationHistory.prevPrev = locationHistoryFromStore?.prev;
    }
      if(locationHistory.cur=="/"){
        locationHistory.prev=undefined;
        locationHistory.prevPrev=undefined;
      }

      if(locationHistory.prevPrev && locationHistory.prevPrev!="/"){
        locationHistory.prev = locationHistory.prevPrev;
        locationHistory.prevPrev=undefined;
      }

      if(locationHistory.cur.indexOf('setCard.detail.html')>=0
        && locationHistory.prev==="/"){
          locationHistory.prev="/setCards.html";
      }

    sessionStorage.setItem(NAVIGATION_STORAGE,JSON.stringify(locationHistory))
    
    setRoute((prev)=> (locationHistory) )
  }, [location]);
  
  return <LastLocationContext.Provider value={route}>
    {children}
  </LastLocationContext.Provider>
}

export default LastLocationProvider;