import React, { Suspense, useState, useEffect, lazy } from "react";
import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

import Loader from "./components/layout/loader";
import LayoutRegistrationSteps from "./templates/layoutRegistrationSteps";
import FakeHeader from "./templates/_fakeHeader";
import ReservationContextProvider from "./contexts/reservationContext";
import SettingsContextProvider from "./contexts/settingsContext";
import SessionContextProvider from "./contexts/sessionContext";
import config from "./config/config";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import MyAccount from "./utils/account";

import useData from "./stores/dataStore";
import useAccount from "./stores/accountStore";
import WebtrekkProvider from "./components/webtrekk/webtrekkProvider";
import LastLocationProvider from "./contexts/lasLocationProvider";
import Confirmation from "./pages/confirmation";
import SessionTimeout from "./pages/sessionTimeout";
import SessionTimeoutModal from "./pages/sessionTimeou.Modal";

const TimeTable = lazy(() => import("./pages/timeTable"));
const SetCards = lazy(() => import("./pages/setCards"));
const Playground = lazy(() => import("./pages/playground"));
const Participant = lazy(() => import("./pages/participant"));
const Participants = lazy(() => import("./pages/participants"));
const Sponsors = lazy(() => import("./pages/sponsors"));
const EventDetail = lazy(() => import("./pages/event.detail"));
const SetCardDetail = lazy(() => import("./pages/setCard.detail.html"));
const Summary = lazy(() => import("./pages/summary"));
const Watchlist = lazy(() => import("./pages/watchlist"));

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [{ eventData, allEventItems }, { fetchData, initializeWebtrekk }] =
    useData();
  const [
    { isLoggedIn },
    { setBookmarks, fetchProfile, logout, initAccountStore },
  ] = useAccount();

  useEffect(() => {
    if (eventData && eventData.id) {
      config.event.id = eventData.id;
      config.event.findId = eventData.findId || null;
      isLoggedIn && fetchBookmarks();
    }
  }, [eventData]);

  const fetchBookmarks = async () => {
    if (eventData.id) {
      const bookmarks = await MyAccount.getBookmarks(eventData.id);
      setBookmarks(bookmarks, allEventItems);
    }
  };

  async function checkSession() {
    let sessionUrl = window?.cdcaccwidget?.cdcApiUrl
      ? window.cdcaccwidget.cdcApiUrl + "/cdc/userinfo"
      : config.myAccount.sessionUrl;

    const response = await fetch(sessionUrl, {
      credentials: "include",
    });

    if (response.ok) {
      // console.log('SESSION OK')

      // login();
      fetchProfile();
      fetchBookmarks();

      return true;
    } else {
      // console.log('SESSION NOT FOUND')

      logout();
      return null;
    }
  }

  useEffect(() => {
    isLoggedIn ? fetchBookmarks() : setBookmarks([], allEventItems);
  }, [isLoggedIn]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchQueryParam = searchParams.get("q");
    if (searchQueryParam)
      window.history.pushState("", "", "/#/?q=" + searchQueryParam);

    // fetch data
    async function loadData() {
      await fetchData();
      setIsLoading(false);
    }
    loadData();
    initAccountStore();

    /* tslint:disable-next-line */
    if (window["wt"]) initializeWebtrekk(window["wt"]);

    // @ts-ignore */}
    if (window.cdcaccwidget) {
      checkSession();

      window.cdcaccwidget.onLogin = () => {
        console.log("EVCAL :: ", "onLogin CDC");
        checkSession();
      };
      window.cdcaccwidget.onLogout = () => {
        console.log("EVCAL :: ", "onLogout CDC");
        logout();
      };
    } else {
      // try to check session also if cdcaccwidget is not defined
      checkSession();
    }
  }, []);

  const basePath = config.basePath;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Suspense fallback={<Loader />}>
          <Router /*basename={basePath}*/>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              {!config.isProduction && <FakeHeader />}

              <SettingsContextProvider>
                <ReservationContextProvider>
                  <SessionContextProvider>
                    <LastLocationProvider>
                      <WebtrekkProvider>
                        {config.isDefaultLayout() ? (
                          <>
                            <Routes path={basePath}>
                              <Route exact path="/" element={<TimeTable />} />

                              <Route
                                exact
                                path="/watchlist.html"
                                element={<Watchlist />}
                              ></Route>
                              <Route
                                path="/confirmation.html"
                                element={<Confirmation />}
                              ></Route>
                              <Route
                                path={"/event.detail.html/:slug.html"}
                                element={<EventDetail />}
                              ></Route>
                              <Route
                                path={"/setCard.detail.html/:slug.html"}
                                element={<SetCardDetail />}
                              ></Route>
                              <Route
                                path="/participant.html"
                                element={<Participant />}
                              ></Route>
                              <Route
                                path="/setCards.html"
                                element={<SetCards />}
                              ></Route>
                              <Route
                                path="/sponsors.html"
                                element={<Sponsors />}
                              ></Route>
                              <Route
                                path="/timeout.html"
                                element={<SessionTimeout />}
                              ></Route>
                              <Route
                                path="/playground.html"
                                element={<Playground />}
                              ></Route>

                              <Route
                                path="/participants.html"
                                element={
                                  <LayoutRegistrationSteps step={1}>
                                    <Participants />
                                  </LayoutRegistrationSteps>
                                }
                              ></Route>

                              <Route
                                path="/summary.html"
                                element={
                                  <LayoutRegistrationSteps step={2}>
                                    <Summary />
                                  </LayoutRegistrationSteps>
                                }
                              ></Route>

                              <Route path="*" element={<TimeTable />} />
                            </Routes>
                            <SessionTimeoutModal />
                          </>
                        ) : (
                          <TimeTable />
                        )}
                      </WebtrekkProvider>
                    </LastLocationProvider>
                  </SessionContextProvider>
                </ReservationContextProvider>
              </SettingsContextProvider>
            </QueryParamProvider>
          </Router>
        </Suspense>
      )}
    </>
  );
};

export default App;
