
import {useState,useEffect} from 'react'

const useStateWithSessionStorage = (sessionStorageKey,initialData) => {
  const [data, setData] = useState(
    sessionStorage.getItem(sessionStorageKey)?
    JSON.parse(sessionStorage.getItem(sessionStorageKey)) : initialData
  );
  useEffect(() => {
    // eslint-disable-next-line
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(data));
  }, [data]);
  return [data, setData];
};

export default useStateWithSessionStorage; 