import { useMemo, useEffect, useState } from "react";
import {
  useQueryParams,
  StringParam,
  DelimitedArrayParam,
} from "use-query-params";
import { CustomDateParm } from "../utils/customQueryParams";
import useData from "../stores/dataStore";

export const FilterCategories = [
  {
    type: "program",
    field: "slug",
    storeName: "allPrograms",
    name: "Program",
    eventItemField: "programSlug",
  },
  {
    type: "location",
    field: "slug",
    storeName: "allLocations",
    name: "Location",
    eventItemField: "resourceSlug",
  },
  {
    type: "legend",
    field: "slug",
    minEventItemsFound: 0,
    storeName: "allLegends",
    name: "Legend",
    eventItemField: "legends.slug",
    hideInMenu: false,
  },
  {
    type: "track",
    field: "slug",
    storeName: "allTracks",
    minEventItemsFound: 0,
    name: "Track",
    eventItemField: "trackId",
    hideInMenu: false,
  },
  {
    type: "eventType",
    field: "slug",
    storeName: "allEventTypes",
    name: "EventType",
    mustMatch: true,
    eventItemField: "eventType",
  },
  {
    type: "language",
    field: "slug",
    storeName: "allLanguages",
    name: "Language",
    mustMatch: true,
    eventItemField: ["language", "simultaneousTranslationsData"],
  },
];
export const FilterCategoriesAsObject = FilterCategories.reduce(function (
  r,
  e
) {
  r[e.type] = DelimitedArrayParam;
  return r;
},
{});

export function useFilter() {
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    day: CustomDateParm,
    ...FilterCategoriesAsObject,
  });
  const [tempSelectedFilters, setTempSelectedFilters] = useState([]);
  const [tempEventItemsCount, setTempEventItemsCount] = useState(0);
  const [
    {
      /*tempSelectedFilters*/ selectedFilters,
      searchTermIsSet,
      allPrograms,
      allLegends,
      allLocations,
      allEventTypes,
      allTracks,
      allLanguages,
    },
    { getFilteredEventItens, applyFilters: applyFiltersInDataStore },
  ] = useData();

  const initFilters = () => {
    const _tempFilters = [];
    FilterCategories.forEach((filterCategory) => {
      const queryCategory = query[filterCategory.type];

      queryCategory &&
        queryCategory.forEach((filterValue) => {
          const newFilter = addTempFilter(
            filterCategory.type,
            filterValue,
            false
          );
          if (newFilter) _tempFilters.push(newFilter);
        });
    });
    applyFiltersInDataStore(_tempFilters);
    setTempSelectedFilters([..._tempFilters]);
  };
  const removeAndApplyFilters = (category, value) => {
    const filters = query[category];
    const filteredCategory = filters.filter((filter) => filter !== value);
    setQuery(
      {
        ...query,
        [category]: filteredCategory.length > 0 ? filteredCategory : undefined,
      },
      "push"
    );
  };

  const resetTempFilters = () => {
    setTempSelectedFilters([...selectedFilters]);
  };

  const clearFilters = () => {
    setTempSelectedFilters([]);
    applyFiltersInDataStore([]);
  };

  const addFilter = (category, value, name) => {
    const tempFilters = [
      ...tempSelectedFilters,
      addTempFilter(category, value),
    ];
    applyFilters(tempFilters);
  };

  const applyFilters = (_filters) => {
    const filtersToApply = _filters || tempSelectedFilters;
    const filters = FilterCategories.reduce(
      function (r, e) {
        const values = filtersToApply
          .filter((f) => f.category === e.type)
          .map((filter) => filter.value);
        if (values.length > 0) {
          r[e.type] = values;
        } else {
          r[e.type] = undefined;
        }
        return r;
      },
      { undefined }
    ); //{}

    setQuery(
      {
        ...query,
        ...filters,
      },
      "push"
    );
  };

  const addTempFilter = (category, value, setData = true) => {
    const dataCategories = {
      allLegends,
      allLocations,
      allPrograms,
      allEventTypes,
      allTracks,
      allLanguages,
    };
    const selectedCategory = FilterCategories.find(
      (fc) => fc.type === category
    );
    if (selectedCategory) {
      var name = "";
      try {
        name = dataCategories[selectedCategory.storeName].find(
          (item) => item[selectedCategory.field].toString() === value.toString()
        ).name;
      } catch (e) {}

      const updateState =
        name.length > 0 &&
        tempSelectedFilters.findIndex(
          (it) => it.category === category && it.value === value
        ) < 0;

      // if (1<2 || updateState) {
      const newTempFilter = {
        category: category,
        value: value,
        name: name,
      };
      if (setData) {
        setTempSelectedFilters((tempSelectedFilters) => [
          ...tempSelectedFilters,
          newTempFilter,
        ]);
      }
      return newTempFilter;
      // }
    }
    return null;
  };

  const hasFilter = (category, value) => {
    return (
      tempSelectedFilters
        .filter((f) => f !== null)
        .findIndex(
          (filter) => filter.category === category && filter.value === value
        ) >= 0
    );
  };

  const removeTempFilter = (category, value) => {
    setTempSelectedFilters((tempSelectedFilters) => [
      ...tempSelectedFilters.filter(
        (filter) =>
          filter.category !== category ||
          (filter.category === category && filter.value !== value)
      ),
    ]);
  };

  useEffect(() => {
    setTempEventItemsCount(
      getFilteredEventItens(true, tempSelectedFilters).length
    );
  }, [tempSelectedFilters]);

  useEffect(() => {
    const hasQueryFilter = Object.values(query).filter((it) => !!it).length;
    if (hasQueryFilter || searchTermIsSet) initFilters();
  }, [query, searchTermIsSet]);

  return {
    query,
    setQuery,
    resetTempFilters,
    clearFilters,
    hasFilter,
    addTempFilter,
    removeTempFilter,
    removeAndApplyFilters,
    tempSelectedFilters,
    tempEventItemsCount,
    applyFilters,
    addFilter,
  };
}
