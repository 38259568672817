import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../components/elements/button";
import { breakpoints } from "../utils/media";
import { SessionContext } from "../contexts/sessionContext";
import { animateScroll as scroll } from "react-scroll";
import Icon from "../components/elements/icon";
import trackingUtils from "../utils/trackingUtils";
import BackButton from "../components/elements/backButton";
import useData from "../stores/dataStore";
import { getLanguage } from "../utils/api";

const StyledWrapperSuccess = styled.div`
  text-align: center;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 30px;
  .icon {
    width: 80px;
    margin-bottom: 30px;
    font-size: 6em;
  }
  & h4 {
    width: auto;
    font-weight: 700;
  }
  & .success_body {
    width: auto;
    font-size: 16pt;
    p {
      width: 100%;
    }
  }
`;

const StyledInfoBox = styled.div`
  background-color: grey;
  color: white;
  display: flex;
  padding: 20px 15px 20px 15px;
  max-width: 880px;
  margin: 0 auto;

  @media (max-width: ${breakpoints.mobile + "px"}) {
    flex-wrap: wrap;
  }

  & svg.info {
    width: 20px;
  }
  & .info-box-msg {
    flex: 1 1 60%;
    padding: 0px 30px 0px 15px;
    & p {
      color: white;
      width: 100%;
      margin-bottom: 0;
    }
    & span.info-box-bold {
      font-weight: 700;
    }
  }
  & div.info-box-button-wrapper {
    flex: 0 1 auto;

    @media (max-width: ${breakpoints.mobile + "px"}) {
      width: 100%;
      flex: 0 1 auto;
      text-align: center;
    }
  }
`;

const Confirmation = (props) => {
  const { t } = useTranslation();
  const { onTimeout } = useContext(SessionContext);
  const [{ eventData }, { getTemplateValue }] = useData();
  const locale = getLanguage();

  useEffect(() => {
    scroll.scrollToTop();
    onTimeout();
  }, []);

  const onBuyTicket = (location) => {
    trackingUtils.onRedeemVoucher();
  };
  var url = !eventData.ticketShopUrl
    ? ""
    : eventData.ticketShopUrl.indexOf("http") < 0
    ? `https://${eventData.ticketShopUrl}`
    : eventData.ticketShopUrl;

  const headline = getTemplateValue(
    "web__reservation-confirmation",
    "subject",
    locale
  );
  const description = getTemplateValue(
    "web__reservation-confirmation",
    "html",
    locale
  );
  const descriptionTwo = getTemplateValue(
    "web__reservation-confirmation",
    "plainText",
    locale
  );
  const buttonText = getTemplateValue(
    "web__reservation-confirmation",
    "buttonText",
    locale
  );
  const buttonLink = getTemplateValue(
    "web__reservation-confirmation",
    "buttonLink",
    locale
  );
  if (buttonLink?.length > 0) url = buttonLink;

  const showBoxOne = getTemplateValue(
    "web__reservation-confirmation-box-one",
    "enabled"
  );
  const showBoxTwo = getTemplateValue(
    "web__reservation-confirmation-box-two",
    "enabled"
  );

  const headlineBoxOne = getTemplateValue(
    "web__reservation-confirmation-box-one",
    "subject",
    locale
  );
  const descriptionBoxOne = getTemplateValue(
    "web__reservation-confirmation-box-one",
    "html",
    locale
  );
  const buttonTextBoxOne = getTemplateValue(
    "web__reservation-confirmation-box-one",
    "buttonText",
    locale
  );
  const buttonLinkBoxOne = getTemplateValue(
    "web__reservation-confirmation-box-one",
    "buttonLink",
    locale
  );

  const headlineBoxTwo = getTemplateValue(
    "web__reservation-confirmation-box-two",
    "subject",
    locale
  );
  const descriptionBoxTwo = getTemplateValue(
    "web__reservation-confirmation-box-two",
    "html",
    locale
  );
  const buttonTextBoxTwo = getTemplateValue(
    "web__reservation-confirmation-box-two",
    "buttonText",
    locale
  );
  const buttonLinkBoxTwo = getTemplateValue(
    "web__reservation-confirmation-box-two",
    "buttonLink",
    locale
  );

  return (
    <>
      <BackButton />
      <StyledWrapperSuccess>
        <span>
          <Icon type="checkmark" />
        </span>
        <h4>{headline || t("confirmation.registration_success")}</h4>
        <div className="success_body">
          {<div dangerouslySetInnerHTML={{ __html: description }} /> || (
            <>
              {t("confirmation.registration_success_email")}
              <br />
              {t("confirmation.registration_success_msg", {
                event: eventData.title,
              })}
            </>
          )}
        </div>
      </StyledWrapperSuccess>

      <StyledInfoBox className="info-box">
        <div>
          <svg
            className="info"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 176 176"
          >
            <title>Asset 2</title>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  d="M88,0a88,88,0,1,0,88,88A88,88,0,0,0,88,0Zm8.1,31.38-2.36,81H82.27L79.91,31.38ZM88,146.91c-5.9,0-10-4.56-10-10.46,0-6.07,4.22-10.45,10-10.45,6.07,0,10,4.38,10,10.45C98,142.35,94.07,146.91,88,146.91Z"
                  fill="#fff"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="info-box-msg">
          {descriptionTwo ? (
            <div
              dangerouslySetInnerHTML={{
                __html: descriptionTwo?.replace("{event}", eventData.title),
              }}
            />
          ) : (
            <p>
              <span className="info-box-bold">
                {t("confirmation.info-box-bold")}
              </span>
              {t(
                eventData.permalink === "2021/hypermotion-2021"
                  ? "hypermotion_2021.confirmation.info-box-body"
                  : "confirmation.info-box-body",
                { event: eventData.title }
              )}{" "}
              {props.missingTickets && props.missingTickets > 0
                ? t("confirmation.info-box-missing-tickets", {
                    count: props.missingTickets,
                  })
                : ""}
            </p>
          )}
        </div>
        {buttonText && (
          <div className="info-box-button-wrapper">
            {eventData.permalink !== "2021/hypermotion-2021" && (
              <Button
                onClick={() => onBuyTicket(url)}
                primary
                target="_blank"
                href={url}
              >
                {buttonText || t("confirmation.button-buy-tickets")}
              </Button>
            )}
          </div>
        )}
      </StyledInfoBox>

      <div
        style={{ marginTop: "8em" }}
        className="m-teaser-container"
        data-t-name="TeaserContainer"
      >
        <div className="row">
          {showBoxOne && (
            <div className="col-sm-6 m-teaser-container__left-column">
              <div
                className="m-teaser h-background h-background--halfwidth-left"
                data-t-name="Teaser"
              >
                <a
                  className="m-teaser__link"
                  href={`${
                    buttonLinkBoxOne || "http://www.messefrankfurt.com/apps"
                  }`}
                >
                  <div className="m-teaser__inner">
                    <div className="m-teaser__content">
                      <h3>{headlineBoxOne || t("confirmation.appHeadline")}</h3>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            descriptionBoxOne || t("confirmation.appText"),
                        }}
                      />
                      {buttonTextBoxOne && (
                        <span className="btn btn-default btn-icon">
                          <Icon type="app" />
                          {buttonTextBoxOne || t("confirmation.appAction")}
                        </span>
                      )}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          )}
          {showBoxTwo && (
            <div className="col-sm-6 m-teaser-container__right-column">
              <div
                className="m-teaser h-background h-background--halfwidth-right"
                data-t-name="Teaser"
                data-t-id="18"
              >
                <a
                  className="m-teaser__link"
                  href={`${
                    buttonLinkBoxTwo ||
                    "https://www.messefrankfurt.com/frankfurt/de/anreise.html"
                  }`}
                >
                  <div className="m-teaser__inner">
                    <div className="m-teaser__content">
                      <h3>
                        {headlineBoxTwo || t("confirmation.travelHeadline")}
                      </h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            descriptionBoxTwo || t("confirmation.travelText"),
                        }}
                      />
                      {buttonTextBoxTwo && (
                        <span className="btn btn-default btn-icon">
                          <Icon type="location" />
                          {buttonTextBoxTwo || t("confirmation.travelAction")}
                        </span>
                      )}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Confirmation;
