import { useState, useEffect } from "react";

const useStateWithLocalStorage = (localStorageKey, initialData) => {
  const [data, setData] = useState(
    localStorage.getItem(localStorageKey)
      ? JSON.parse(localStorage.getItem(localStorageKey))
      : initialData
  );
  useEffect(() => {
    // eslint-disable-next-line
    localStorage.setItem(localStorageKey, JSON.stringify(data));
  }, [data]);
  return [data, setData];
};

export default useStateWithLocalStorage;
