import { useParams, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useMemo } from 'react';

export function useRouter() {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    // const match = useMatch();
  
    // Return our custom router object
    // Memoize so that a new object is only returned if something changes
    return useMemo(() => {
      return {
        // For convenience add push(), replace(), pathname at top level
        push: navigate,
        //replace: navigate.replace,
        pathname: location.pathname,
        // Merge params and parsed query string into single "query" object
        // so that they can be used interchangeably.
        // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
        query: {
          ...queryString.parse(location.search), // Convert string to object
          ...params
        },
        // Include match, location, history objects so we have
        // access to extra React Router functionality if needed.
        //match,
        location,
        navigate
      };
    }, [params, 
      // match
      , location, navigate]);
  }