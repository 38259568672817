import React from "react";
import styled from "styled-components";
import { StringHelper } from "../../utils/helpers";
import Tooltip from "../tooltip/tooltip";
import Badge from "./badge";
import Loader from "../layout/loader";

const StyledButton = styled.button`
  position: relative;
  overflow: hidden;
  .a-spinner:before {
    width: 30px;
    height: 30px;
  }
  ${(props) =>
    props.small &&
    `
    max-width: 30px;
    min-width: 30px !important;
    text-align: center;
    padding: 0;
    height: 30px;
    line-height: 30px;`}
  ${(props) =>
    props.middle &&
    `max-width: 80px;
    min-width: ${(props) =>
      props.onlyIconOnMobile ? "auto !important" : "80px !important"};
    margin: 4px;
    text-align: center;
    padding: 0;
    height: 60px;
    line-height: 30px;`}
  .badge {
    color: #fff;
    left: 25px;
    min-width: 20px;
    padding: 3px 5px 5px;
    position: absolute;
    top: 5px;

    @media screen and (min-width: 480px) {
      left: 25px;
    }
    @media screen and (min-width: 768px) {
      left: 25px;
    }
    @media screen and (min-width: 1024px) {
      left: 55px;
    }
  }
  ${(props) => props.error && "border-color: red"};

  &.btn-icon-single-xxs {
    height: 50px;
    min-width: auto !important;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;
    @media screen and (min-width: 1024px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
`;
const StyledForm = styled.form`
  display: inline-block;
`;

const ButtonElement = ({ onClick, loading = false, ...props }) => {
  function handleClick(e) {
    if (onClick && !props.href) {
      onClick();
    }
  }
  const className = `btn btn-${props.primary ? "primary" : "default"}${
    props.size === " small" ? " btn--small" : ""
  }${props.inverted ? " btn-inverted" : ""}${
    props.onlyIconOnMobile ? " btn-icon-single-xxs" : ""
  }${
    props.icon
      ? !props.children
        ? ` btn-icon-single btn-${props.icon}`
        : " btn-icon"
      : ""
  }${props.className ? ` ${props.className}` : ""}`;

  return (
    <StyledButton {...props} className={className} onClick={handleClick}>
      {props.icon && <span className={`icon icon-${props.icon}`}></span>}
      {props.badge > 0 && <Badge>{props.badge}</Badge>}
      <span
        className={`btn-label ${
          props.onlyIconOnMobile ? "btn-label-hidden-xxs" : ""
        }`}
      >
        {props.children}
      </span>
      {loading && <Loader overlay />}
    </StyledButton>
  );
};

const Button = ({ onClick, ...props }) => {
  const slug = StringHelper.stringToSlug(props.tooltip);
  const id = slug && `btn-${slug}`;
  const additionalProps = id ? { id: id } : {};
  return (
    <Tooltip id={id} title={props.tooltip}>
      {!props.href ? (
        <ButtonElement
          {...additionalProps}
          onClick={onClick}
          {...props}
        ></ButtonElement>
      ) : (
        <StyledForm
          {...additionalProps}
          target={props.target || "_self"}
          action={props.href}
        >
          <ButtonElement onClick={onClick} {...props}></ButtonElement>
        </StyledForm>
      )}
    </Tooltip>
  );
};

export default Button;
