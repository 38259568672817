import { DateHelper, StringHelper } from "./helpers";
let sendData = false;

class TrackingUtils {
  onFilter(q, filters, countResults) {
    const wt = window.wt || null;
    const wt_event = window.wt_event || null;
    if (!wt) return false;

    
    var customParameters= {};
    var customParametersEvent= {};

    
    if (q?.length>0 || filters?.length>0) {
      if (q?.length>0) {
        wt.internalSearch = q;
        wt_event.internalSearch = q;
      }

      if (filters && filters.length > 0) {
        
        customParameters= {
            18: `${filters
              .map((filter) => filter.category + "_" + filter.value)
              .join(";")}`,
            51: `${countResults}`,
          }
        
          customParametersEvent = {
            11: `${countResults}`,
          }
      } else if(q?.length>0) {
        customParameters= {
            51: `${countResults}`,
          }
          customParametersEvent = {
            11: `${countResults}`,
          }
      }
      wt.customParameter = customParameters;
      wt.sendinfo();
      if(wt_event){
        wt_event.customParameter = customParametersEvent;
        wt_event.sendinfo();     
      }
    }
  }

  trackDetail(
    title = "",
    location = "",
    program = "",
    time = "",
    page = "",
    legend = "",
    participants = 0
  ) {
    const param = {
      customParameter: {
        1: time,
        2: location,
        3: title,
        //4: 'events',
        20: page,
        12: legend,
        17: participants,
      },
    };
    console.log('TRACKING :: trackDetail window.wt',window.wt);
    console.log('TRACKING :: new cp',param);

    if (window.wt) {
      window.wt.contentGroup["4"] = "events";
      window.wt.contentGroup["5"] = page;
      window.wt.contentGroup["6"] = "not-categorized";
      window.wt.contentGroup["7"] = "not-categorized";
      window.wt.customParameter = param.customParameter;
      window.wt.sendinfo();
    }
    if (window.wt_event) {
      window.wt.contentGroup["4"] = "events";
      window.wt_event.contentGroup["5"] = page;
      window.wt_event.contentGroup["6"] = "not-categorized";
      window.wt_event.contentGroup["7"] = "not-categorized";
      window.wt_event.customParameter = param.customParameter;
      window.wt_event.sendinfo();
    }
  }



  onChangePage(path, includeEvents = false) {
    console.log('TRACKING :: changeContentId to',path);
    this.changeContentId(path);
    
    /*if (window.wt && window.wt.contentGroup["7"]) {
      window.wt.contentGroup["7"] = path;
      window.wt.contentGroup["4"] = "events";
      if (sendData) window.wt.sendinfo();
    }
    if (window.wt_event && window.wt_event.contentGroup["7"]) {
      window.wt_event.contentGroup["7"] = path;
      window.wt_event.contentGroup["4"] = "events";

      if (sendData) window.wt_event.sendinfo();
    }*/
  }
  changeContentId(path) {
    // return false;
    path = path.replaceAll(".html", "_html").replaceAll(".", "-");

    if (window.wt) {
      // makes awatchlist/watcdhlist
      var contentId = window.wt.contentId
        .replace("#.", "")
        .split(".")
        .slice(0, -1);

      contentId.push(path);
      window.wt.contentId = contentId.join(".");
    }
    if (window.wt_event) {
      var contentId = window.wt_event.contentId.split(".").slice(0, -1);
      contentId.push(path);
      window.wt_event.contentId = contentId.join(".");
    }
  }
  

  parseParams(querystring) {
    // parse query string
    const params = new URLSearchParams(querystring);
    const obj = {};

    // iterate over all keys
    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key);
      } else {
        obj[key] = params.get(key);
      }
    }

    return obj;
  }


  onPrint() {
    this.send(31, "print_preview");
  }
  onSharePage(event, slug) {
    const param = {
      customEcommerceParameter: {
        4: "share_event",
        31: `share_event_${event}_${slug}`,
      },
    };
    if (window.wt) window.wt.sendinfo(param);
    if (window.wt_event) window.wt_event.sendinfo(param);
  }

  // onSearch(q, results) {
  //   if (window.wt) {
  //     window.wt.internalSearch = q;
  //     window.wt.sendinfo();
  //   }
  //   if (window.wt_event) {
  //     window.wt_event.internalSearch = q;
  //     window.wt_event.sendinfo();
  //   }
  // }

  onBookmarkEvent(slug) {
    const param = {
      customEcommerceParameter: {
        4: "bookmark_event",
        22: `bookmark_event_${slug}`,
      },
    };
    const param2 = {
      customEcommerceParameter: {
        4: "bookmark_event",
        12: `bookmark_event_${slug}`,
      },
    };
    if (window.wt) window.wt.sendinfo(param);
    if (window.wt_event) window.wt_event.sendinfo(param2);
  }
  onICSDownload() {
    const param = {
      customEcommerceParameter: {
        31: "ics_download",
      },
    };
    if (window.wt) {
      window.wt.sendinfo(param);
    }
  }

  onRestartSession() {
    const param = {
      customEcommerceParameter: {
        31: "restart_session",
      },
    };
    if (window.wt) window.wt.sendinfo(param);
  }

  onBuyTicket() {
    const param = {
      customEcommerceParameter: {
        31: "buy_ticket",
      },
    };
    if (window.wt) window.wt.sendinfo(param);
  }
  onRedeemVoucher() {
    const param = {
      customEcommerceParameter: {
        31: "redeem_voucher",
      },
    };
    if (window.wt) window.wt.sendinfo(param);
  }

  onBookEvents(events) {
    events.map((event) => {
      const title = event.title;
      const location = `${event.hall || ""}${
        event.booth ? ", " + event.booth : ""
      }${event.location ? ", " + event.location : ""}`;
      const time = `${DateHelper.getDate(
        event.startDate
      )}, ${DateHelper.getTimeRange(event.startDate, event.endDate)}`;

      const params = {
        customEcommerceParameter: {
          31: "event_signup",
        },
        customParameter: {
          1: time,
          2: location || "undefined",
          3: title,
          17: `${
            event.participants && Array.isArray(event.participants)
              ? event.participants.length
              : 1
          }`,
        },
      };

      if (window.wt) {
        window.wt.customParameter=params.customParameter;
        window.wt.customEcommerceParameter = params.customEcommerceParameter;
        window.wt.sendinfo();
      }
      if (window.wt_event) {
        window.wt_event.customParameter=params.customParameter;
        window.wt_event.customEcommerceParameter = params.customEcommerceParameter;
        window.wt_event.sendinfo();
      }
    });
  }

  send(paramId, paramValue) {
    const param = {
      customEcommerceParameter: {
        [`${paramId}`]: paramValue,
      },
    };
    if (window.wt) window.wt.sendinfo(param);
  }

  // TNR START
  registrationStart() {
    this.send(31, "registration_start");
  }
  registrationDontStart() {
    this.send(31, "registration_abort");
  }
  registrationCompleted() {
    this.send(31, "registration_complete");
  }
  registrationCancel() {
    this.send(31, "registration_cancel");
  }
  registrationTimeout() {
    this.send(31, "registration_timeout");
  }
  // TNR END
}

export default new TrackingUtils();
