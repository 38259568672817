import React, {  useContext } from 'react';
import Modal from '../components/modal/modal';
import { SessionContext } from '../contexts/sessionContext';
import SessionTimeout from './sessionTimeout';

const SessionTimeoutModal = () => {
	const { showTimeoutModal, setShowTimeoutModal } = useContext(SessionContext);

	return (<>{showTimeoutModal &&
		<Modal onClose={()=>setShowTimeoutModal(false)} isOpen={true}>
			<SessionTimeout isModal={true} />
		</Modal>
	}</>

	);
}

export default SessionTimeoutModal; 