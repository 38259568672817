import React from 'react'

const Col = props => {
    return (
        <div className="col-xxs-12" {...props}>
            {props.children}
        </div>
    )
}

export default Col
