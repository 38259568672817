
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import Icon from '../elements/icon';

const steps = [
    { enabled: true, label: 'participant.participants'},
    { enabled: false, label:'summary.summary'},
]

const Steps = ({step=1,...props}) => {
    const {t} = useTranslation();
    
    return (
        <>
        <div className="breadcrumbs">
            <ul>
            {
                steps.map((crump,index)=>
                    <li key={`step-${index}`} className={`${step===(index+1) && 'is-active border color-event border-color-event'}`}>
                        <span className="number">{index+1}</span>
                        <span className="text">{t(crump.label)}</span>
                    </li>
                )
            }
            </ul>
        </div>
        {
         step>1 && <Link data-reference="back-to-participants" to="/participants.html"><Icon type="left" /> {t('functions.backTo')} {t('participant.participants')} </Link>
        }
        </>
    )
}

export default Steps
