import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import LinkWithQuery from "./linkWithQuery";

const StyledBackButton = styled(LinkWithQuery)`
  margin-bottom: 60px;
`;

const BackButton = ({ title, targetLocation }) => {
  const { t } = useTranslation();
  return (
    <StyledBackButton
      to={targetLocation || "/"}
      data-reference={"back"}
      className="a-link a-link--back"
    >
      <span>{title || t("functions.back")}</span>
    </StyledBackButton>
  );
};

export default BackButton;
