import React from "react";
import styled from "styled-components";

const StyledBadge = styled.span``;

const Badge = ({ children }) => {
  return <StyledBadge className="badge">{children}</StyledBadge>;
};

export default Badge;
