import Axios from "axios";
import config from "../config/config";
import i18next from 'i18next';
import { configure } from 'axios-hooks'


export function getLanguage() {
    let lang = i18next.language;
    if (!lang) {
      lang = config.lang;
    }
    return lang.match(/^[a-z]+/i)[0].toLowerCase();
}
export function getLocale() {
  return i18next.language.toLowerCase()
}
const axiosInstance = Axios.create({
  baseURL: config.api.baseUrl,
  responseType: 'json',
});

axiosInstance.interceptors.request.use(function (conf) {
  // Do something before request is sent
  if(config.isPreview)conf.url = `${conf.url}${conf.url.indexOf('?')<0?'?':'&'}preview=true`
  if(config.api.useApiGateway)conf.headers.common['apikey'] = config.api.gatewayKey;
  return conf;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export function initAxiosHookConfiguration() {
  configure({ axiosInstance })
}

export default axiosInstance;
