import { createStore, createHook } from "react-sweet-state";
import config from "../config/config";
import api from "../utils/api";
import { ArrayHelper, DateHelper, StringHelper } from "../utils/helpers";
//import MiniSearch from 'minisearch'
import { FilterCategories as FilterCategoriesArray } from "../hooks/useFilter";
import { Themes } from "./themeStore";
import Fuse from "fuse.js";
import { chain, uniq, flatten } from "lodash";

/* @TODO
In futre after upgrade to vite
import chain from "lodash/chain";
import uniq from "lodash/uniq";
import flatten from "lodash/flatten";
*/

// FilterCategories as Object
const FilterCategories = FilterCategoriesArray.reduce(function (r, e) {
  r[e.type] = e;
  return r;
}, {});

const getDays = (data) => {
  return chain(data)
    .sortBy("startDate")
    .groupBy((item) => DateHelper.getDateAsDayjs(item.startDate))
    .map(function (v, i) {
      return {
        value: i,
        label: DateHelper.getDate(i, "date"), //DateHelper.getOnlyDate(i),
      };
    })
    .value();
};

export const SORT_BY = {
  DAYS: "DAYS",
  RESOURCE: "RESOURCES",
};

// value of the store on initialisation
const initialState = {
  searchTerm: "",
  searchTermIsSet: false,
  sortBy: SORT_BY.RESOURCE,
  eventData: {},
  eventItems: [],
  allSponsors: [],
  allSetCards: [],
  allEventItems: [],
  allSessions: [],
  allPrograms: [],
  allLanguages: [],
  allLegends: [],
  allLocations: [],
  allTags: [],
  allTracks: [],
  allEventTypes: [],
  textTemplates: [],
  allDays: [],
  basePath: "",
  labels: {},
  days: [],
  selectedDay: null,
  selectedFilters: [],
  filters: [],
  tempSelectedFilters: [],
  tempEventItemsCount: 0,
  timeZone: {},
  wt: {},
  theme: Themes.DEFAULT,
  isConference: false,
  alwaysShowStatus: false,
  apiOffline: false,
};
// actions that trigger store mutation
const actions = {
  fetchData:
    () =>
    async ({ setState, dispatch }) => {
      try {
        const eventResponse = await api.get(
          config.api.endpoints.eventData
            .replace(":permalink", encodeURIComponent(config.event.permalink))
            .replace(":language", config.locale)
        );
        const eventData = eventResponse.data;
        const _isConference = eventData?.eventType === "CONFERENCE";

        const eventItemsUrl = _isConference
          ? config.api.endpoints.eventItemsWithAllData
          : config.api.endpoints.eventItems;

        const eventItemResponse = await api.get(
          eventItemsUrl
            .replace(":eventId", eventResponse.data.id)
            .replace(":language", config.locale)
        );

        //const textTemplateResponse = await api.get(config.api.endpoints.textTemplate.replace(':eventId', eventResponse.data.id).replace(':type','unsubscribe-from-event').replace(':language', config.locale));
        const textTemplateResponse = await api.get(
          config.api.endpoints.textTemplates
            .replace(":eventId", eventResponse.data.id)
            .replace(":language", config.locale)
        );

        eventData.slug = StringHelper.stringToSlug(eventData.title).replaceAll(
          "_",
          "-"
        );
        // @TODO for mulitlegend. remove mapping to single legendId
        const allEventItems = eventItemResponse.data;
        let eventItems =
          eventData.eventType === "CONFERENCE"
            ? allEventItems
                .filter((eventItem) => !eventItem.trackId)
                .map((eventItem) => ({
                  ...eventItem,
                }))
            : allEventItems.map((eventItem) => ({
                ...eventItem,
              }));

        let sessions =
          eventData.eventType === "CONFERENCE"
            ? allEventItems
                .filter((eventItem) => eventItem.trackId)
                .map((eventItem) => ({
                  ...eventItem,
                }))
            : [];

        let _allTracks = eventData.tracks;
        let foundTrackIdsInItems =
          eventData.eventType === "CONFERENCE"
            ? allEventItems
                .filter(
                  (eventItem) =>
                    eventItem.conferenceType === "TRACK" &&
                    eventItem.sessionIds?.length > 0
                )
                .map((eventItem) => eventItem.id)
            : [];
        const tracks = _allTracks.filter((it) =>
          foundTrackIdsInItems?.includes(it.id)
        );

        const textTemplateData = textTemplateResponse.data;

        const eventHasLocations = !!eventData.locations;
        const eventHasLegends = !!eventData.legends;

        let _allLanguages = [];
        let _allEventTypes = [];

        eventItems.forEach((eventItem) => {
          // add language if not exist
          // fix language to two letter language
          if (eventItem.language && eventItem.language.length > 2)
            eventItem.language = eventItem.language.substring(0, 2);

          const foundLanguages = [
            eventItem.language,
            ...(eventItem.simultaneousTranslationsData
              ? eventItem.simultaneousTranslationsData.split(",")
              : []),
          ];
          _allLanguages = [
            ..._allLanguages,
            ...foundLanguages.filter((l) => !_allLanguages.includes(l)),
          ];

          // add eventType if not exist
          if (
            !eventItem.eventType /*|| (eventItem.eventType && eventItem.eventType==='onsite')'*/
          ) {
            eventItem.eventType = "onsite";
          }

          //fix eventType Names
          if (eventItem.eventType === "stream + onsite")
            eventItem.eventType = "streamonsite";
          if (eventItem.eventType === "stream") eventItem.eventType = "online";
          const eventTypeName = eventItem.eventType;

          if (
            eventTypeName !== "Session" &&
            eventTypeName !== "Track" &&
            !_allEventTypes.find((et) => et.slug === eventTypeName)
          ) {
            _allEventTypes.push({
              slug: eventTypeName,
              name: `eventType.${eventTypeName}`,
            });
          }
        });
        _allLanguages = _allLanguages
          .filter((l) => l !== undefined)
          .map((l) => ({
            slug: l,
            name: `language.${l}`,
          }));
        // console.log(allEventItems)

        setState({
          eventData: eventData,
          theme: eventData.template
            ? Themes[`${eventData.template.toUpperCase()}`] || Themes.DEFAULT
            : Themes.DEFAULT,
          allPrograms: eventData.programs,
          timeZone: {
            name: eventData.timezone || "Europe/Berlin",
            timezoneShortName: eventData?.timezoneUTCOffset || null,
          },
          textTemplates: textTemplateData,
          basePath: eventData.basePath,
          labels: eventData.labels
            ? eventData.labels.reduce(function (r, e) {
                r[e.name] = e.value;
                return r;
              }, {})
            : {},
          allLocations: eventHasLocations
            ? eventData.locations.sort((a, b) => (a.order < b.order ? -1 : 1))
            : flatten(eventData.programs.map((it) => it.locations)).sort(
                (a, b) => (a.order < b.order ? -1 : 1)
              ),
          allTags: uniq(
            flatten(allEventItems.map((it) => it.tags)) //_.uniq(_
          ),
          allLegends: eventHasLegends
            ? eventData.legends
            : flatten(eventData.programs.map((it) => it.legends)),
          allEventItems: eventItems,
          allSessions: sessions,
          allTracks: tracks.map((track) => ({
            name: track.name,
            slug: track.id,
          })),
          allDays: getDays(eventItems),
          allEventTypes: _allEventTypes,
          allLanguages: _allLanguages,
          isConference: _isConference,
        });
        dispatch(actions.setSponsors());
        dispatch(actions.setSetCards());
        dispatch(actions.processData());
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    },
  initializeWebtrekk:
    (initialWTConfig) =>
    ({ setState }) => {
      setState({
        wt: initialWTConfig,
      });
    },

  setApiOffline:
    (status) =>
    ({ setState, dispatch }) => {
      setState({
        apiOffline: status,
      });
    },
  setDay:
    (day) =>
    ({ setState, dispatch }) => {
      setState({
        selectedDay: day,
      });
      dispatch(actions.processData());
    },

  calculateResults:
    () =>
    ({ setState, getState }) => {},
  applyFilters:
    (tempSelectedFilters) =>
    ({ setState, dispatch }) => {
      setState({
        selectedFilters: [...tempSelectedFilters],
      });
      dispatch(actions.processData());
    },

  filterEventItems: (allEventItems, searchTerm) => () => {
    const searchTerms = searchTerm
      .split([",", " ", "+"])
      .map((it) => it.toLowerCase());
    const attrsToSearch = [
      "title",
      "programName",
      "shortDescription",
      "hall",
      "location",
      "setCards.name",
      "tags",
      "setCards.company.name",
      "sponsors.name",
    ];

    return allEventItems.filter((ev) =>
      searchTerms.find((term) =>
        attrsToSearch.find((attributeName) => {
          let attr = ev[attributeName];
          if (attributeName.indexOf("setCards") == 0) {
            if (!ev.setCards) return false;
            if (ev.setCards.length == 0) return false;
            return (
              ev.setCards.findIndex(
                (setCard) =>
                  setCard.name.toLowerCase().includes(term) ||
                  (setCard.company &&
                    setCard.company?.name?.toLowerCase().includes(term))
              ) >= 0
            );
          }
          if (attributeName.indexOf("sponsors") == 0) {
            if (!ev.sponsors) return false;
            if (ev.sponsors.length == 0) return false;
            return (
              ev.sponsors.findIndex((sponsor) =>
                sponsor.name.toLowerCase().includes(term)
              ) >= 0
            );
          }

          if (!attr) return false;
          if (typeof attr === "string")
            return attr?.toLowerCase().includes(term);
          if (Array.isArray(attr))
            return attr.find((arrayItem) =>
              arrayItem?.toLowerCase().includes(term)
            );
          return false;
        })
      )
    );
  },
  getFilteredEventItens:
    (useTemporaryFilters = false, tempSelectedFilters = []) =>
    ({ getState, dispatch }) => {
      const { searchTerm, allEventItems: eventItems, allSessions } = getState();
      let allEventItems = [...eventItems, ...allSessions];
      const selectedFilters = (
        useTemporaryFilters
          ? tempSelectedFilters //getState().tempSelectedFilters
          : getState().selectedFilters
      ).filter((it) => it !== null);

      let searchResults = [];
      let searchResultsIds = [];

      if (searchTerm /*|| filterOptions*/) {
        searchResults = dispatch(
          actions.filterEventItems(allEventItems, searchTerm)
        );
        searchResultsIds = searchResults.map((result) => ({ id: result }));
      }

      let filteredEventItems = searchTerm
        ? [...searchResults]
        : [...allEventItems];

      if (selectedFilters.length > 0) {
        function FilterEventItemsByFilterSelection(record, filters) {
          return filters.find((filter) => {
            const eventItemField =
              FilterCategories[filter.category].eventItemField;
            const recordField = Array.isArray(eventItemField)
              ? eventItemField
                  .filter((field) => record[field])
                  .map((field) => {
                    return record[field].indexOf(",")
                      ? record[field].split(",")
                      : record[field];
                  })
                  .flat()
              : record[eventItemField] || record[eventItemField.split(".")[0]];

            if (!recordField) return false;
            if (Array.isArray(recordField)) {
              if (eventItemField.indexOf(".") < 0) {
                if (!filter.value) return false;
                return recordField.includes(filter.value.toString());
              }
              return (
                filter.value &&
                recordField.find(
                  (it) =>
                    it[eventItemField.split(".")[1]] === filter.value.toString()
                )
              );
            }
            return (
              filter.value && filter.value.toString() === recordField.toString()
            );
          });
        }

        const filtersMatchOR = selectedFilters.filter(
          (filter) => !FilterCategories[filter.category].mustMatch
        );
        const filtersMatchAND = selectedFilters.filter(
          (filter) => FilterCategories[filter.category].mustMatch
        );

        const filtersToApply = [
          filtersMatchOR || [],
          ...Object.values(
            filtersMatchAND
              ? ArrayHelper.groupBy(filtersMatchAND, "category")
              : {}
          ),
        ];

        filtersToApply.forEach((filterBy) => {
          if (filterBy && filterBy.length > 0)
            filteredEventItems = filteredEventItems.filter((record) =>
              FilterEventItemsByFilterSelection(record, filterBy)
            );
        });
      }

      // if conference check for found sessions and return track instead of session
      [...filteredEventItems.filter((it) => it.trackId)].forEach(
        (eventItem) => {
          const track = eventItems.find((it) => it.id === eventItem.trackId);
          const foundTrack = filteredEventItems.find(
            (it) => it.id === eventItem.trackId
          );
          if (!foundTrack) filteredEventItems.push(track);
        }
      );
      filteredEventItems = filteredEventItems.filter(
        (eventItem) => !eventItem.trackId
      );

      return filteredEventItems;
    },

  processData:
    () =>
    ({ setState, getState, dispatch }) => {
      const { selectedDay } = getState();

      const filteredEventItems = dispatch(actions.getFilteredEventItens());

      const filteredDays = getDays(filteredEventItems);

      const _selectedDay = selectedDay || filteredDays[0];

      setState({
        eventItems: filteredEventItems,
        days: filteredDays,
        selectedDay: _selectedDay,
        tempEventItemsCount: filteredEventItems.length,
      });
    },
  getTemplate:
    (templateType) =>
    ({ getState }) => {
      const { textTemplates } = getState();
      let templ = textTemplates
        ? [...textTemplates.filter((it) => it.type === templateType)]
        : null;

      return Array.isArray(templ) ? templ[0] : templ;
    },
  getTemplateValue:
    (templateType, attr, locale) =>
    ({ dispatch }) => {
      var templ = dispatch(actions.getTemplate(templateType));
      if (!templ) return "";
      if (!Array.isArray(templ[attr])) return templ[attr];

      templ =
        templ[attr].find(
          (t) => t?.language?.substring(0, 2).toLowerCase() === locale
        )?.value || "";
      return templ;
    },
  getSessionsByTrackId:
    (trackId) =>
    ({ getState }) => {
      const { allSessions } = getState();
      let sessions = allSessions
        ? [...allSessions.filter((it) => it.trackId === trackId)]
        : null;

      return sessions;
    },
  getSessions:
    (sessionIds) =>
    ({ getState }) => {
      const { allSessions } = getState();
      // console.log("getSession", sessionIds);
      let sessions = allSessions
        ? [...allSessions.filter((it) => sessionIds.includes(it.id))]
          .sort((a,b)=>DateHelper.isBefore(a.startDate,b.startDate)?-1:1)
        : null;

      return sessions;
    },
  getEventItemBySlug:
    (slug) =>
    ({ getState }) => {
      const { allEventItems } = getState();
      let eventItem = allEventItems
        ? { ...allEventItems.find((it) => it.slug === slug) }
        : null;

      return eventItem;
    },
    getLanguageSlugBySlug:
    (slug,lang) =>
    ({ getState }) => {
      const { allEventItems } = getState();

      let eventItem = allEventItems
        ? { ...allEventItems.find((it) => it.slug === slug) }
        : null;
      

      // try in another language
      if(allEventItems && lang && !eventItem?.id){
        eventItem = { ...allEventItems.find((it) => it.languageSlugs && it.languageSlugs.find(ls=>ls.slug===slug )) }
      }

      const foundSlug = eventItem.languageSlugs ? eventItem.languageSlugs.find(ls=>ls.language.toLowerCase()===lang.toLowerCase())?.slug : eventItem.slug;
      return foundSlug || eventItem.slug;
    },
  getRelatedEventItems:
    (item) =>
    ({ getState }) => {
      const { allEventItems } = getState();

      const value = [
        item.title,
        item.shortDescription,
        item?.tags?.join(" "),
        item?.legends?.map((l) => l.name).join(" "),
        item?.setCards?.map((sc) => sc?.name)?.join(" "),
      ]?.join(" ");

      const options = {
        includeScore: true,
        includeMatches: true,
        threshold: 0.8,
        keys: [
          "title",
          "description",
          { name: "tags", weight: 2 },
          { name: "legends.name", weight: 2 },
          { name: "setCards.name", weight: 0.5 },
        ],
      };

      const fuse = new Fuse(
        allEventItems.filter((eventItem) => eventItem.id !== item.id && (eventItem.sourceId!==item.id)),
        options
      );

      const results = fuse.search(value).slice(0, 3);
      return results.map((result) => result.item);
    },

  getEventItemAdditionalDates:
    (id) =>
    ({ getState }) => {
      const { allEventItems } = getState();

      return allEventItems
        ? [
            ...allEventItems.filter(
              (eventItem) => eventItem.id === id || eventItem.sourceId === id
            ),
          ]
        : [];
    },
    getEventItemById:
    (id) =>
    ({ getState }) => {
      const { allEventItems } = getState();

      return allEventItems.find(it=>it.id===id);
    },
  setSearchTerm:
    (searchTerm) =>
    ({ setState, dispatch }) => {
      setState({
        searchTerm: searchTerm,
        searchTermIsSet: true,
      });
      dispatch(actions.processData());
    },

  setSortBy:
    (sort_by) =>
    ({ setState }) => {
      setState({
        sortBy: sort_by,
      });
    },
  getSetCardBySlug:
    (slug) =>
    ({ getState }) => {
      const { allSetCards } = getState();
      const foundSetCard = allSetCards.find(
        (setCard) => setCard.slug.toString() === slug.toString()
      );
      return foundSetCard;
    },
  getEventItemsBySetCardSlug:
    (slug) =>
    ({ getState }) => {
      const { allSetCards } = getState();
      const foundSetCard = allSetCards.find(
        (setCard) => setCard.slug.toString() === slug.toString()
      );
      return foundSetCard ? foundSetCard.eventItems : [];
    },
  setSetCards:
    () =>
    ({ setState, getState }) => {
      const { allEventItems, allSessions } = getState();
      var _setCards = [];
      [...allEventItems, ...allSessions].forEach((eventItem) => {
        eventItem.setCards &&
          eventItem.setCards.forEach((setCard) => {
            var foundSetCard = _setCards.find((sc) => sc.slug === setCard.slug);
            const _eventItem = {
              id: eventItem.id,
              title: eventItem.title,
              allDay: eventItem.allDay,
              slug: eventItem.trackId
                ? allEventItems.find((it) => it.id === eventItem.trackId).slug
                : eventItem.slug,
              startDate: eventItem.startDate,
              endDate: eventItem.endDate,
            };

            if (!foundSetCard) {
              setCard.eventItems = [_eventItem];
              _setCards.push(setCard);
            } else {
              foundSetCard.eventItems.push(_eventItem);
            }
          });
      });

      setState({
        allSetCards: _setCards,
      });
    },
  setSponsors:
    () =>
    ({ setState, getState }) => {
      const { allEventItems } = getState();
      var _sponsors = [];
      allEventItems.forEach((eventItem) => {
        eventItem.sponsors &&
          eventItem.sponsors.forEach((sponsor) => {
            var foundSponsor = _sponsors.find((sp) => sp.id === sponsor.id);
            const _eventItem = {
              title: eventItem.title,
              allDay: eventItem.allDay,
              slug: eventItem.slug,
              startDate: eventItem.startDate,
              endDate: eventItem.endDate,
            };

            if (!foundSponsor) {
              sponsor.eventItems = [_eventItem];
              _sponsors.push(sponsor);
            } else {
              foundSponsor.eventItems.push(_eventItem);
            }
          });
      });

      setState({
        allSponsors: _sponsors,
      });
    },
  clearSearchTerm:
    () =>
    ({ setState }) => {
      setState({
        searchTerm: "",
      });
    },
  setShowStatus:
    (status) =>
    ({ setState }) => {
      setState({
        alwaysShowStatus: status,
      });
    },
  setTheme:
    (theme) =>
    ({ setState }) => {
      setState({
        theme: theme,
      });
    },
};

const DataStore = createStore({ initialState, actions });
const useData = createHook(DataStore);
export default useData;
