import React from 'react'
import styled from 'styled-components';

const StyledIcon = styled.span`
   font-size: 21px;
`

const Icon = props => {
    return (
        <StyledIcon className={`icon icon-${props.type} ${props.size==='big'&&'h3'}`} {...props}/>
    )
}

export default Icon