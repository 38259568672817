import { css } from "styled-components";

export const breakpoints = {
  desktopXL: 1200,
  desktop: 992,
  tablet: 768,
  mobile: 576,
  listView: 815,
};

export const media = Object.keys(breakpoints).reduce((result, key) => {
  result[key] = (...args) => css`
    @media (min-width: ${breakpoints[key] + "px"}) {
      ${css(...args)}
    }
  `;

  return result;
}, {});

export const maxWidth = Object.keys(breakpoints).reduce((result, key) => {
  result[key] = (...args) => css`
    @media (max-width: ${breakpoints[key] - 1 + "px"}) {
      ${css(...args)}
    }
  `;

  return result;
}, {});
