import React,{useEffect,useRef} from 'react'
/* global jQuery */

const Tooltip = ({title,id,children}) => {
    useEffect(() => {
      try{
      if (title && typeof jQuery !== 'undefined' && jQuery().tooltip) {
          jQuery(`#${id}`).tooltip({
            placement: 'top',
            title: title,
            trigger: 'hover',
          });
        }}catch(e){
          console.log("Something went wrong with jQuery Tooltip",e)
        }
    }, [])
    
    return ( <>
        {children}
    </> );
}
 
export default Tooltip