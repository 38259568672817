import React, { useEffect, useContext } from "react";
import Row from "../components/layout/row";
import Col from "../components/layout/col";
import Steps from "../components/steps/steps";
import Clock from "../components/elements/clock";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { SessionContext } from "../contexts/sessionContext";

const StyledLayoutHolder = styled.div`
  > div:first-child {
    margin-bottom: 3em;
  }
`;
const LayoutRegistrationSteps = (props) => {
  return (
    <StyledLayoutHolder>
      <Row>
        <Col className="col-xxs-8 col-sm-10">
          <Steps step={props.step} />
        </Col>
        <Col className="col-xxs-4 col-xs-4 col-sm-2 text-right">
          <Clock />
        </Col>
      </Row>
      <Row>{props.children}</Row>
    </StyledLayoutHolder>
  );
};

export default LayoutRegistrationSteps;
