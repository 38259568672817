import { createStore, createHook } from 'react-sweet-state';
import config from '../config/config';


export const Themes = {
  DEFAULT: "default",
  GRID: "grid",
  COMPACT: "compact",
  TINY: "tiny",
}

const ThemeStore = createStore({
  // value of the store on initialisation
  initialState: {
    layout: config.layout, // GRID OR LIST
    showOffCanvas:false,
    showFilterNavigation:false,
    showShare:false,
    isMobile:false,
    isTablet:false,
    isDetailView:false,
    needAuthentification:undefined,
    isTouchDevice: ('ontouchstart' in document.documentElement),
  },
  // actions that trigger store mutation
  actions: {
    setLayout: (layout) => ({ setState }) => {
      setState({
        layout: layout
      })
    },
    setNeedAuthentification: (needAuthentification) => ({ setState }) => {
      setState({
        needAuthentification: needAuthentification
      })
    },
    setIsDetailView: (isDetailView) => ({ setState }) => {
      setState({
        isDetailView: isDetailView
      })
    },
    closeOffCanvas: () => ({ setState }) => {
      setState({
        showFilterNavigation: false,
        showShare:false,
        showOffCanvas:false
      })
    },
    showFilterNavigation: () => ({ setState }) => {
      setState({
        showFilterNavigation: true,
        showOffCanvas:true
      })
    },
    setIsMobile: (value) => ({ setState }) => {
      setState({
        isMobile: value
      })
    },
    setIsTablet: (value) => ({ setState }) => {
      setState({
        isTablet: value
      })
    },
    showShare: () => ({ setState }) => {
      if(window && document){
        document.querySelectorAll('[data-t-decorator="SharePage"]').forEach(el=>el.classList.add('o-off-canvas--open'))
      }
    },
    openOffCanvs: () => ({ setState }) => {
      setState({
        showOffCanvas:true,
      })
    },
    toggleOffCanvas: () => ({ setState,getState }) => {
        setState({
          showOffCanvas: !getState().showOffCanvas
        })
      },
  } 
});

const  useTheme = createHook(ThemeStore);
export default useTheme;
