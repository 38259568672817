import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import './utils/i18n';
import { initAxiosHookConfiguration } from './utils/api';

initAxiosHookConfiguration();

const rootElem = document.querySelector('div[data-application-name="mf-evcal-root"]');//document.getElementById('mf-evcal-root')
  const root = createRoot(rootElem);
  root.render(<App />);