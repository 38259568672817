const Participants = [
    {
        firstName: 'Max',
        lastName: 'Mustermann',
        email: 's.aszmann@gmail.com',
        companyName: 'Messe Frankfurt GmbH',
        hasTicket: true,
        myself:true,
        countryCode: "DEU",
        id: '5dce893e832557c9a39a2369',
        accountId: '5dce893e832557c9a39a2369',
        image: 'https://idp.messefrankfurt.com/myaccount-service/content/public/profile/image/MF-PER-REJUXW?t=1572427012323'
    }
];

const CDCProfilePeter = {
    "sub": "2c0ee9e0f5360419a96ee81a2a496cbb",
    "addresses": [],
    "country": "NLD",
    "lastName": "Difloe",
    "countryCodePhone": "+49",
    "gender": "m",
    "language": "en-GB",
    "photoURL": "https://cdns.gigya.com/photos/370073476903/1e67863655bf4ec091b910fc4158cd38/orig?ts=638620990420607604",
    "firstName": "Peter",
    "phone": "580701",
    "position": "MF-INT-000027",
    "department": "MF-INT-003119",
    "email": "s.aszmann+cdc@gmail.com",
    "thumbnailURL": "https://cdns.gigya.com/photos/370073476903/1e67863655bf4ec091b910fc4158cd38/thumbnail?ts=638620990423962159",
};
const CDCProfileBasti = {
        "sub": "19a96ee81a2a496cbb2c0ee9e0f53604",
        "addresses": [],
        "country": "DEU",
        "lastName": "Bauer",
        "countryCodePhone": "+49",
        "gender": "m",
        "language": "de-DE",
        "photoURL": "https://cdns.gigya.com/photos/370073476903/1e67863655bf4ec091b910fc4158cd38/orig?ts=638620990420607604",
        "firstName": "Basti",
        "phone": "580701",
        "position": "MF-INT-000027",
        "department": "MF-INT-003119",
        "email": "basti@garage51.de",
        "thumbnailURL": "https://cdns.gigya.com/photos/370073476903/1e67863655bf4ec091b910fc4158cd38/thumbnail?ts=638620990423962159",
        "company": [
            {
                "legalName": "Volkswagen Software Asset Management GmbH",
                "country": "DEU",
                "localName": "Volkswagen Software Asset Management GmbH",
                "addresses": [
                    {
                        "country": "DEU",
                        "streetName": "Berliner Ring",
                        "postalCode": "38440",
                        "locality": "Wolfsburg",
                        "houseNumber": "2",
                        "type": "POSTAL",
                        "addressId": "CDC-958e635b-d905-4def-a14e-dd56daff67ae"
                    }
                ],
                "companyId": "CDC-1781195c-4870-4dd7-9c0f-f334b439c8f9",
                "dunsId": "506758783",
                "url": "www.vw.com"
            }
        ]
};

const Events = [{
    "id":541698,
    "title":"Workshop Eins 🤪",
    "slug":"workshop-eins",
    "startDate":"2019-09-21T08:00:00",
    "endDate":"2019-09-21T12:00:00",
    "programId":17746,
    "shortDescription":"",
    "programName":"Workshops - Programm",
    "programSlug":"workshops-programm",
    "legends":[],
    "highlight":false,
    "break":false,
    "hasDetail":false,
    "allDay":false,
    "repeat":false,
    "isDuplicate":false,
    "duplicateId":0,
    "hall":"Zelt Festhalle/h1",
    "simultaneousTranslationsData":"EN,DE",
    "origProgramId":17746,
    "registrationId":"5de8c8bbb733fc86f11c2a5e",
    "isBookable":true,
    "ticketsAvailable":11,
    "isFavorite":true,
    "registrationState":0,
    "participants":[]
},
{
    "id":541714,
    "title":"Workshop Zwei",
    "slug":"workshop-zwei",
    "startDate":"2019-09-20T08:00:00",
    "endDate":"2019-09-20T09:00:00",
    "programId":17746,
    "shortDescription":"",
    "programName":"Workshops - Programm",
    "programSlug":"workshops-programm",
    "legends":[],
    "highlight":false,
    "break":false,
    "hasDetail":false,
    "allDay":false,
    "repeat":false,
    "isDuplicate":false,
    "duplicateId":0,
    "simultaneousTranslationsData":"",
    "origProgramId":17746,
    "registrationId":"5de8c8d0b733fc86f11c2a5f",
    "isBookable":true,
    "ticketsAvailable":7,
    "isFavorite":true,
    "registrationState":0,
    "participants":[]
},
{
    "id":541717,
    "title":"Workshop Vier",
    "slug":"workshop-vier",
    "startDate":"2019-09-20T08:00:00",
    "endDate":"2019-09-20T09:00:00",
    "programId":17746,
    "shortDescription":"",
    "programName":"Workshops - Programm",
    "programSlug":"workshops-programm",
    "legends":[],
    "highlight":false,
    "break":false,
    "hasDetail":false,
    "allDay":false,
    "repeat":false,
    "isDuplicate":false,
    "duplicateId":0,
    "simultaneousTranslationsData":"",
    "origProgramId":17746,
    "registrationId":"5de914080d0d08902e15381c",
    "isBookable":true,
    "ticketsAvailable":10,
    "isFavorite":true,
    "registrationState":0,
    "participants":[],
},
{
    "id":541715,
    "title":"Workshop Drei - mit ein wenig mehr Text im Titel",
    "slug":"workshop-drei-mit-ein-wenig-mehr-text-im-titel",
    "startDate":"2019-09-20T13:00:00",
    "endDate":"2019-09-20T17:00:00",
    "programId":17746,
    "shortDescription":"",
    "programName":"Workshops - Programm",
    "programSlug":"workshops-programm",
    "legends":[{"id":13215,"title":"Grün","color":"#b8e986"}],
    "highlight":false,
    "break":false,
    "hasDetail":false,
    "allDay":false,
    "repeat":false,
    "isDuplicate":false,
    "duplicateId":0,
    "simultaneousTranslationsData":"",
    "origProgramId":17746,
    "registrationId":"5de912bc0d0d08902e153819",
    "isBookable":true,
    "ticketsAvailable":10,
    "isFavorite":true,
    "registrationState":0,
    "participants":[],
}];


export default {
    Participants,
    Events,
    CDCProfileBasti,
    CDCProfilePeter
}