import React from "react";
import styled from "styled-components";

const StyledLoader = styled.div`
  ${(props) =>
    props.overlay || props.small
      ? `
display: flex;
position: absolute;
top: 0;
left: 0;
height: 100%;
width: 100%;
z-index:400;
align-items: center;
justify-content: center;
    margin-bottom: 40px;
:after {
    left: 50%;
    position:absolute;
    margin-left: -50vw;
    width: 100vw;
    background-color: #f3f3f3;
    content: " ";
    display: block;
    height: 100%;
    top: 0;
    z-index: 0;
    background-color: rgba(255,255,255,.7);
}
`
      : ""}
  ${(props) =>
    props.small &&
    `
overflow:hidden;
&:before {
    width: 16px; height: 16px;
}
`}
`;

const Loader = ({ ...props }) => {
  return (
    <StyledLoader
      {...props}
      className="a-spinner a-spinner--medium center-block"
    />
  );
};

export default Loader;
