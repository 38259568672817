import React,{createContext,useEffect} from 'react'
import useStateWithLocalStorage from '../hooks/useLocalStorage';
import config from '../config/config';

export const SettingsContext = createContext();

const SettingsContextProvider = (props) => {
  const [settings, setSettings] = useStateWithLocalStorage('settings',config.defaultSettings);
  

  // run once
  useEffect(() => {
    //fetch data
    if(!settings)setSettings(config.defaultSettings)
    return () => {
      // cleanup
    };
  }, [])

  const updateSettings = (input) => {
       setSettings(input)
  }


  return ( 
    <SettingsContext.Provider value={{settings,updateSettings}}>
      {props.children}
    </SettingsContext.Provider>
   );
}

export default SettingsContextProvider;
