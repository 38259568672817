import SelectOptions from "../data/selectOptions";
import { getLocale } from "./api";
import config from "../config/config";

import "dayjs/locale/fr";
import "dayjs/locale/es";
import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-hk";
import "dayjs/locale/zh-tw";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";

dayjs.extend(localizedFormat);
dayjs.extend(utc);

const DateFormats = {
  time: "LT",
  shortTime: "H:mm",
  //shortDate: 'LL',
  date: `DD. MMMM 'YY`,
  shortDate: `DD. MMM 'YY`,
  query: "YYYY-MM-DD",
  db: "YYYY-MM-DDTHH:mm:00",
  shareCalendar: "YYYYMMDDTHHmm00",
  shareCalendarAllDay: "YYYYMMDD",
  zh: {
    dateNavigation: "YYYY/MM/DD",
    dateEvent: "YYYY年MM月DD日",
  },
  ["en-us"]: {
    date: `MMMM DD, YYYY`,
    shortDate: `MMM DD, YYYY`,
  },
  ["en-ca"]: {
    date: `MMMM DD, YYYY`,
    shortDate: `MMM DD, YYYY`,
  },
};

const CookieHelper = {
  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);

    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
};

const StringHelper = {
  findFirstUppercaseOrFirstUpperCase(inputString) {
    if(!inputString)return "#";
    inputString = inputString.replace(/\s/g, "");
    for (let i = 0; i < inputString.length; i++) {
      if (inputString[i] === inputString[i].toUpperCase()) {
        return inputString[i];
      }
    }

    // If no uppercase letter is found, return the first letter of the string in uppercase
    return inputString[0].toUpperCase();
  },
  getFullName(participant) {
    return `${
      participant.title
        ? SelectOptions.titleSelectOptions.find(
            (i) => i.value === participant.title
          ).label + " "
        : ""
    }${participant.firstName ? participant.firstName : ""} ${
      participant.lastName ? participant.lastName : ""
    }`;
  },
  stripHtml(html) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  },
  getLocation(event) {
    return `${event.hall ? event.hall : ""}${
      event.booth ? (event.hall ? ", " : "") + event.booth : ""
    }${event.location ? ", " + event.location : ""}`;
  },
  stringToSlug(text) {
    if (!text || text.length === 0) return "";
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;+";
    const to = "aaaaaeeeeeiiiiooooouuuunc-------";

    let newText = text;
    try {
      newText = text
        .split("")
        .map((letter, i) =>
          letter.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
        );
    } catch (err) {}
    return newText
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/&/g, "-y-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-"); // Replace multiple - with single -
  },
  find(items, text) {
    text = text.split(" ");
    return items.filter(function (item) {
      return text.every(function (el) {
        return item.content.indexOf(el) > -1;
      });
    });
  },
};

const CommonHelper = {
  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  },
};

const ImageHelper = {
  getImagePath(file, width = 82) {
    if (!file) return "";
    try {
      if (file && file.file && file.file.indexOf("http") == 0) return file.file;
    } catch (e) {}
    const fileName = file.file || file;
    let crop = file.cropDataObject;
    if (crop && typeof crop === "string") crop = JSON.parse(crop);

    return `${config.api.endpoints.image
      .replace("{{file}}", fileName.replaceAll("/", ""))
      .replace("{{w}}", width)}`;
  },
};

const ArrayHelper = {
  groupBy(array, key) {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  },
};

const DateHelper = {
  dateRangeFormated(from, to) {
    return `${dayjs(from).format("D")} - ${dayjs(to).format("D MMM 'YY")}`;
  },
  PartsOfDay: [
    [20, "night"],
    [12, "afternoon"],
    [0, "late_morning"],
    //[0,  'morning']
  ],
  getPartOfDay(date) {
    const PartsOfDay = DateHelper.PartsOfDay;
    var hr = dayjs(date).hour();
    for (let i = 0; i < PartsOfDay.length; i++) {
      if (hr >= PartsOfDay[i][0]) {
        return PartsOfDay[i][1];
      }
    }
  },
  getDate(date, format) {
    const locale = getLocale();

    format = format || "shortDate"; //DateFormats.shortDate;

    const overwriteFormat = DateFormats[locale]
      ? DateFormats[locale][format]
      : null;

    format = overwriteFormat || DateFormats[format];

    if (locale.indexOf("zh") == 0) format = DateFormats.zh.dateEvent;

    dayjs.locale(locale);
    return `${dayjs(date).format(format || DateFormats.shortDate)}`;
  },
  getOnlyDate(date) {
    const locale = getLocale();
    let format = DateFormats.date;
    if (locale.indexOf("zh") == 0) format = DateFormats.zh.dateNavigation;

    dayjs.locale(locale);
    return dayjs(date).format(format);
  },
  getDateForQuery(date, format,addDays=0) {
    return `${dayjs(date).add(addDays, 'day').format(format || DateFormats.query)}`; //this.getDate(date, DateFormats.query)
  },
  getDateAsDayjs(date, format) {
    return dayjs(date).startOf("day");
  },
  getDateTime(date, time) {
    dayjs.locale(getLocale());
    return `${dayjs(date)}`;
  },
  isSameDay(a, b) {
    return dayjs(a).isSame(dayjs(b), "day");
  },
  isToday(a) {
    return this.isSameDay(a, new Date());
  },
  isBefore(a, b) {
    return dayjs(a).isBefore(b ? dayjs(b) : dayjs());
  },
  getTime(date) {
    return dayjs(date || "08:00").format(DateFormats.time);
  },
  getTimeRange(from, to, showAffix = false) {
    dayjs.locale(getLocale());
    let _from = dayjs(from).format(DateFormats.time);
    let _to = dayjs(to).format(DateFormats.time);

    if (_from.indexOf("AM") > 0 && _to.indexOf("AM") > 0) {
      _from = _from.replace("AM", "");
    }
    if (_from.indexOf("PM") > 0 && _to.indexOf("PM") > 0) {
      _from = _from.replace("PM", "");
    }

    return `${_from} - ${_to}${
      showAffix && getLocale() === "de" ? " Uhr" : ""
    }`;
  },
  getDifference(from, to) {
    return dayjs(to) - dayjs(from);
  },
  isInPast(date) {
    return dayjs(date).isBefore(dayjs(), "day");
  },
  checkOverlap(from1, to1, from2, to2) {
    const start1 = dayjs(from1);
    const start2 = dayjs(from2);
    const end1 = dayjs(to1);
    const end2 = dayjs(to2);

    if (start1 >= start2 && start1 < end2) {
      return true;
    } else if (start2 >= start1 && start2 < end1) {
      return true;
    }
    return false;
  },
};

export {
  DateFormats,
  DateHelper,
  CookieHelper,
  StringHelper,
  CommonHelper,
  ImageHelper,
  ArrayHelper,
};
